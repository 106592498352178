@CHARSET "UTF-8";


[n2s-tooltip]{
}

#n2sTooltipPopup{
    display:block;

    background-color: rgba(0,0,0,0.9);
    color: white;
    padding: 10px;

    position:absolute;
    margin-top:-10000px;
    z-index:1600;   
    left:0%;
    font-size:12px;
    line-height:14px;
  
    border-radius: 5px;
    font-family: Lato;
    text-align: center;
    max-width:200px;
    min-width:50px;
    opacity:0;
    
    span{
        white-space: nowrap;
    }

    &.hide{
        opacity:0;
    }

    &.show{
        opacity:1;
        transition: opacity .3s ease-in;
    }

  .arrow{
    position:absolute;
    width: 0; 
    height: 0; 

    &.top{
        bottom:-10px;
        left:-10px;
        margin-left:50%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(0,0,0,0.9);    
    }

    &.bottom{
        top:-10px;
        left:-10px;
        margin-left:50%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgba(0,0,0,0.9);
    }

    &.right{
        top:-10px;
        left:-10px;
        margin-top:50%;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid rgba(0,0,0,0.9);
    }

    &.left{
        top:-10px;
        right:-10px;
        margin-top:50%;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid rgba(0,0,0,0.9);
    }
  }
}
