.n2s-map-popup {
  display: block;
  opacity:1;
  background-color:white;
  border: 1px solid rgba(0,0,0,0.25);
  box-sizing:border-box;
  border-radius:0.3em;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
  z-index: 1;
  position:relative;
  top: - 140px;
  left: - 250px;
  color: $mainColor;
  width:500px;

  & > div{
    margin:5px;
    padding: 0px 0px;
    min-width: 100px;
    min-height: 20px;
    max-height: 90%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow: hidden;
  }

  .n2s-popup-footer{
    border-top: 2px solid $hrMediumColor;
    text-align: right;
  }

  .n2s-popup-arrow {
    position: absolute;
    border-color: transparent;
    border-style: dotted;
    border-width: 11px;
    width: 0px;
    height: 0px;
    margin:0px;
    padding:0px;
    min-width: 0px;
    min-height: 0px;
    box-sizing: border-box;

    &:after{
      position:absolute;
      border: 10px solid transparent;
      content:"";
      width:0px;
      height:0px;
    }

  }

  .n2s-popup-arrow.arrow-bottom {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;

    &:after {
      bottom: 1px;
      margin-left: -10px;
      border-top-color: #ffffff;
      border-bottom-width: 0;
    }
  }
}

.n2s-multimarkermap-wifiarea-info{
  display:block;
  float:left;
  width:335px;

  .unit{
    display: inline-block;
    font-size: 14px;
    width:50%;

    .icon{
      margin-right:5px;
      font-size:25px;
      opacity: 0.8;
      margin-left:10px;
    }

  }
}

.n2s-multimarkermap-wifiarea-links{
  display:inline-block;
  border-left:2px solid $hrMediumColor;
  margin-left: 5px;
  line-height:21px;
  float:right;

  a{
    border-left:3px solid transparent;
    padding:4px 8px;
    text-decoration: none;
    font-size: 12px;
    color: $mainColor;

    &:hover{
      border-color: $highlightColor;
    }
  }

}

.n2s-multimarkermap-wifiarea-name{
  font-size: 14px;
  font-family: Lato;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:320px;
  overflow:hidden;
}

.n2s-multimarkermap-wifiarea-close{
  font-family: score;
  position:absolute;
  top:5px;
  left:325px;
  font-size:20px;
  cursor:pointer;
  opacity:0.8;

  &:hover{
    opacity:1;
  }
}


.n2s-multimarkermap-wifiarea-address{
  font-size: 12px;
  font-family: Lato;
  opacity: 0.7;
  border-bottom: 2px solid $hrMediumColor;
  width:100%;
  padding-bottom:2px;
  margin-bottom:5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:320px;
  overflow:hidden;
}

.n2s-multimarkermap-wifiarea-traffic{
  line-height:25px;
  margin-top:5px;
}

.n2s-multimarkermap-marker{
  font-family:score;

  img{
    width:30px;
    height:30px;
  }
}

.map {
  height: 500px;
  width: 100%;
  background: var(--ol-background-color);
  margin-bottom: 10px;
}