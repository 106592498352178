@CHARSET "UTF-8";

n2s-itemselector {
  margin: 0px;
  min-width:160px;
  width:100%;
  display:block;
  box-sizing:border-box;
  text-align:left;
}

@mixin itemselector-choice{
    background-color:white;
    color: $mainColor;
    cursor: move;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
    box-sizing:border-box;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.3em;
    padding: 5px 2px;
    margin: 5px 0px;
    opacity:0.5;
    display:inline-block;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    
    &.n2s-itemselector-handle{
      cursor: move;       
    }

    .n2s-itemselector-trans{
      float: right;
      opacity: 0;

      cursor: pointer;
      .actions-btn{
        width: 20px;
        height: 20px;
        font-size: 23px !important;
        cursor: unset;
      }
    }

    .n2s-itemselector-required{
      float: right;
      opacity: 0;

      cursor: pointer;

      .itemselector-checkbox{
        display: inline-block;
        width:20px;
        height:20px;
        background-color: $controlBackground;
        border-radius:2px;
        margin-right:5px;
        position:relative;
        top:2px;

        &.selected:after{
          content: '_';
          font-family:'score';
          position: absolute;
          top: 0.1em;
          left: 0.07em;
          font-size: 1.5em;
          line-height: 0.9;          
          transition: all .2s;
          color: $highlightColor;
        }        
      }

      .itemselector-checkbox-label{
        font-size:12px;
        position:relative;
        top:-4px;
        margin-right:5px;
      }

    }

    .text{
      line-height: 15px;
      display: inline-block;
      left: 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: 5px;
    }

    &.fixed-option{
      background-color: $highlightColor;
      color:$light40Color;
      opacity: 1;
      cursor: not-allowed;

      span{
        margin-left:13px;
      }  
    }


    &.selected {
      color:$highlightColor;
      opacity: 1;      

      .n2s-itemselector-required{
        opacity: 1;
      }

      .n2s-itemselector-trans{
        opacity: 1;
      }
    }

    .toggle{
      display:inline;
      width: 50px;
      line-height: 13px;
      position:relative;
      top:1px;
    }
}



.n2s-itemselector-container {
  min-height: 50px;
  border-radius:0.3em;
  margin: 10px 0px;
  background-color: $light40Color;
  padding:10px;
  width:100%;
  box-sizing:border-box;

  .n2s-choice { 
    @include itemselector-choice;  
  }
}

.n2s-choice-ghost { 
  
  @include itemselector-choice;  
  
  position: absolute;
  display: none;
  z-index: 1000;
  opacity:1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

  .n2s-itemselector-required{
    display:none;
  }

  .n2s-itemselector-trans{
    display:none;
  }
}

.n2s-itemselector-container {

  .n2s-choice.transparent {
    opacity: 0 !important;
  }
}

.fixed-item{
  .text{
    top: 5px !important;
  }
}
