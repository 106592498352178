@CHARSET "UTF-8";

n2s-autocombo {
  display: inline-block;
  box-sizing: border-box;
  height: 2em;
  min-width: 150px;
  width: 100%;
}

.n2s-autocombo-container {
  display: inline-table;
  cursor: pointer;
  text-decoration: none;

  /*width:100%;*/
  /*padding: 5px;*/
  background-color: transparent;
  @include border-radius (0.5em);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
  position: relative;

  width:100%;
  box-sizing: border-box;

  /*
      border-radius:1em 1em 1em 1em;
      display:block;
      vertical-align:baseline;
      position:relative;
      min-width: 100px;
      margin: 4px 5px;
      overflow:hidden;
      padding:0.2em 0.3em 0.2em 0.4em;
      box-sizing:border-box;
  */
  /* WHEN ACTIVE */
  /*    background-color: rgb(197, 219, 240); */
}

.n2s-autocombo-row {
  display: table-row;
}

.n2s-autocombo-blur {
  display: table;
  table-layout: fixed;
  font-size: 11px !important;
  border: none !important;
  @include border-radius(0.5em);
  padding: 0px 0px 0px 10px;
  margin: 0px;
  width: 100%;
  background-color: $controlBackground;
  color: $highlightColor;
  /*float: right;*/
  position:absolute;
  top:0px;

  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.n2s-autocombo-icon {
  font-family: score;
  font-size: 15px;
  color: $highlightColor;
  display: table-cell;
  width: 18px;
  padding: 5px 4px 4px 0px;
  text-align: left;
  line-height: 20px;
  text-transform: none;
}

.n2s-autocombo-selection {
  display: table-cell;
  font-size: 11px !important;
  border: none !important;
  color: $highlightColor;
  margin: 0px;
  outline: none !important;
  cursor: pointer;
  vertical-align: middle;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: $mainFont;
}

.n2s-autocombo-caret {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
  padding-right: 5px;
  text-align: center;

  i {
    line-height:1.2em;
  }
}

.n2s-autocombo-focus {
  width: 100%;
}

.n2s-autocombo-container {
  &.open {
    
    .n2s-autocombo-focus {
      display: block;
      box-sizing:border-box;
      padding:5px;
    }

    .n2s-autocombo-blur {
      display: none;
    }

    background-color: $controlBackground;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    /*height: 200px;*/
    z-index: 1000;
    /*padding-bottom: 40px;*/
  }

  &.closed {
    display:inline;

    .n2s-autocombo-focus {
      display: none;
    }

    .n2s-autocombo-blur {
      display: table;
    }
  }
}

.n2s-autocombo-focus n2s-searchbox {
  width: 100%;

  .n2s-searchbox-container {
    margin: 0px !important;

    input{    
      border: 2px solid $dark10ControlBackground !important;

      &:focus{
        border: none;        
        background-color: rgba(255,255,255,0.5) !important;
      }

    }
  }
}

.n2s-autocombo-candidates-wrapper {
  width: 100%;
  position: absolute;

  /* display: table; */
  padding: 0px 4px;
  box-sizing: border-box;

  /* border: 1px solid; */
  margin: 0px;
  left: 0px;
  top: 38px;
}

.n2s-autocombo-candidates {
  background-color: white;
  height: 100%;
}

.n2s-autocombo-summary {
  position: absolute;
  bottom: 0px;
  height: 20px;
  width: 100%;
  margin-left: -7px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: $darkColor;
  text-shadow: white; 

  div {
    width: 100%;
    padding: 4px 7px;
    box-sizing: border-box;
    font-size: 13px;
    text-shadow: 0px 1px 1px rgb(255, 255, 255);
    font-family: $mainFont;
    font-style: italic;
  }

  hr {
    margin: -8px 0px 2px 4px;
    border-top: 1px solid #99BFE4;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid white;
  }
}

.n2s-autocombo-candidates ul.option-list {
  li {
    height: 40px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  margin: 2px;
  box-sizing: border-box;
}
