

n2s-image {
  display: block;
  color: #fff;
  position: relative;

  div {
    &.n2s-image-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      text-align: center;
      border-radius: 0.5em;
      border: 2px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;

      div.n2s-image-status {
        position: absolute;
        top: 50%;
        height: 0px;
        margin: auto;
        width: 100%;
      }
    }

    &.n2s-image-icon {
      position: relative;
      top: -20px;
      margin: auto;
      line-height: 41px;
    }

    &.n2s-image-container {
      &.pending {
        cursor: wait;

        div {
          &.n2s-image-status {
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-animation: spin-clockwise 1s linear infinite;
            -moz-animation: spin-clockwise 1s linear infinite;
            -o-animation: spin-clockwise 1s linear infinite;
            animation: spin-clockwise 1s linear infinite;
          }

          &.n2s-image-icon:after {
            content: 'i';
            font-family: score;
            font-size: 40px;
            top: -20px;
          }
        }
      }

      &.removing {
        cursor: not-allowed;
        border: none;
      }
    }
  }
}

div.n2s-image-progress {
  display: none;
  position: absolute;
  bottom: 2px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;

  span {
    font-size: 70%;
    margin-left: 5px;
  }
}

n2s-image div.n2s-image-container {
  &.uploading div {
    &.n2s-image-container {
      background-image: none !important;
    }

    &.n2s-image-status {
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-animation: spin-clockwise 1s linear infinite;
      -moz-animation: spin-clockwise 1s linear infinite;
      -o-animation: spin-clockwise 1s linear infinite;
      animation: spin-clockwise 1s linear infinite;
    }

    &.n2s-image-icon:after {
      content: 'i';
      font-family: score;
      font-size: 40px;
      top: -20px;
    }

    &.n2s-image-progress {
      display: block;
    }
  }

  &.resolved {
    cursor: pointer;
    border: none;

    div.n2s-image-status {
      display: none;
    }
  }

  &.rejected {
    cursor: not-allowed;

    div.n2s-image-icon:after {
      content: '~';
      font-family: score;
      font-size: 40px;
      top: -20px;
    }
  }
}

/*
n2s-image.editing:hover div.n2s-image-container div.n2s-image-status{
    display:block;
    text-shadow: 0px 0px 4px rgba(0,0,0,1);
}

n2s-image.editing:hover div.n2s-image-container div.n2s-image-icon:after{
    content: 'f';
    font-family:score;
    font-size:40px;
    top:-20px;
}
*/

.n2s-image-remove {
  position: absolute;
  top: -3px;
  left: -6px;
  display: none;
  cursor: pointer;

  i.icon {
    font-size: 25px;
    color: #bbb;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
  }
}

n2s-image.editing .n2s-image-remove {
  display: block;
}



n2s-image.white-bkg{
  color: #777;

  div {
    &.n2s-image-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      text-align: center;
      border-radius: 0.5em;
      border: 2px solid #777;
      box-sizing: border-box;
      /*box-shadow:0px 2px 10px;*/
    }
  }
}

n2s-image.content-size{

  padding:10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  border: 2px solid #777;
  box-sizing: content-size!important;

  div {
    &.n2s-image-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      text-align: center;
      border:none;
      background-color:transparent;
      border-radius:0px;
      box-sizing: content-size!important;
    }
  }
}
