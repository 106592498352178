n2s-colorpicker {
  display: inline-block;
  position: relative;
  width: 64px;
  border-radius: 0.3em;
  padding: 0.2em;
  background-color: $controlBackground;
  box-sizing: border-box;
  color: $highlightColor;
  cursor:pointer;
}

.n2s-colorpicker-wrapper{
  height:20px;
}

.n2s-colorpicker-container {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
}

[popup-id=colorPicker]{
  z-index: 110;
}

.n2s-colorpicker-popup {
  width: 164px;
  padding: 5px;
  background-color: transparent;
  border-radius: 0.5em;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
  position: absolute;
  left: -36px;
}

.n2s-colorpicker-container {
  &.open .n2s-colorpicker-popup {
    display: block;
    background-color: $light40Color;
    border-color: $light20Color;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;

    .n2s-colorpicker-arrow {
      position: absolute;
      border-color: transparent;
      border-style: dotted;
      border-width: 11px;
      width: 0px;
      height: 0px;
      margin:0px;
      padding:0px;
      min-width: 0px;
      min-height: 0px;
      box-sizing: border-box;

      &:after{
        position:absolute;
        border: 10px solid transparent;
        content:"";
        width:0px;
        height:0px;
      }
    }
  }

  &.open.arrow-top .n2s-colorpicker-popup{
    top:10px;

    .n2s-colorpicker-arrow{
      top: -11px;
      left: 50%;
      margin-left: -11px;
      border-bottom-color: $light20Color;
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-top-width: 0;

      &:after {
        top: 1px;
        margin-left: -10px;
        border-bottom-color: $light40Color;
        border-top-width: 0;
      }
    }
  }

  &.open.arrow-bottom .n2s-colorpicker-popup{
      top:-245px;

     .n2s-colorpicker-arrow {
      bottom: -11px;
      left:50%;
      margin-left: -11px;
      border-top-color: $light20Color;
      border-top-color: rgba(0, 0, 0, 0.25);
      border-bottom-width: 0;

      &:after {
        bottom: 1px;
        margin-left: -10px;
        border-top-color: $light40Color;
        border-bottom-width: 0;
      }
    } 
  }

  &.closed .n2s-colorpicker-popup {
    display: none;
  }
}

.n2s-colorpicker-map-canvas {
  margin: 5px;
  cursor: crosshair;
  @include user-select(none);
}

.n2s-colorpicker-hue-canvas {
  //border:1px solid #999;
  margin: 5px 2px;
  cursor: pointer;
  @include user-select(none);
}

.n2s-colorpicker-sample {
  width: 30px;
  height: 20px;
  display: inline-block;
  margin-right:5px;
  box-sizing: border-box;
}

.n2s-colorpicker-trans-preview{
  border: 2px solid $light20Color;
//  box-sizing: border-box;
}

.n2s-colorpicker-trans-input{
  color:transparent !important;
}


.n2s-colorpicker-palette{

  width:240px;
  margin:auto;
  
  .n2s-colorpicker-sample{
    width:38px;
    height:20px;   
    border-radius:0;
    margin:1px 1px 0px 0px!important;
    cursor:pointer;
    padding:0px;
    display:block;
    float:left;
    border: none;
    
    div{
      margin:0px;
      padding:0px;
      width:100%;
      height:100%;
      box-sizing:border-box;
      border:1px solid rgba(0,0,0,0.2);      
    }
  }

  label{
    line-height:30px;
    
    & + input{
      text-align:right;
      margin-left:10px;
    }
  }  
}

.n2s-colorpicker-hex{
  width:38px;
  height:20px;   
  border-radius:0;
  margin:1px 1px 0px 0px!important;
  cursor:pointer;
  padding:0px;
  display:block;
  float: right;
  border: none;
  top: 3px;
  position: relative;
  
  div{
    margin:0px;
    padding:0px;
    width:100%;
    height:100%;
    box-sizing:border-box;
    border:1px solid rgba(0,0,0,0.2);      
  }
}



.n2s-colorpicker-components{
  float:right;
  border-left: 2px solid $hrMediumColor;
  padding-left:5px;
  height: 128px;
  margin:5px 0px;
  text-align: right;
}

.n2s-colorpicker-hexcolor{
  text-align:left !important;
  width:80px;
}

.n2s-colorpicker-map-cursor{
  width: 5px;
  height: 5px;
  border: 1px solid;
  border-radius: 100%;
  position:absolute;
  box-shadow: 0px 0px 1px white;
}

.n2s-colorpicker-maps{
  position:relative;
  float:left;
  @include user-select(none);
}

.n2s-colorpicker-tabs{
  width: 100%;
  list-style: none;

  li{
     color: $light20Color;
     display:inline;
     padding-right:5px;
     padding-left:5px;
     cursor:pointer;
     border-bottom: 4px solid transparent;
     padding-bottom: 4px;

     &:hover{
      border-bottom: 4px solid $light20Color;
     }

     &.selected{
      color: $highlightColor;
      cursor:default;
      border-bottom: 4px solid $highlightColor;
     }
  }  

  hr{
    margin:7px 0px;
  }
}


.n2s-colorpicker-alpha{
  clear:both;

  hr{
    border-top: 1px solid $light50MainColor;
    margin-top: 10px;
  }

  .icon-opacity{
    color: $mainColor;
  }

  n2s-slider {
    width:200px;
  }
}