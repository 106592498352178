@charset "UTF-8";

n2s-modal-container{
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  z-index:200;
  background-color:rgba(0,0,0,0.5);
  padding:10px;

  & > div{
   position:absolute;
   width:100%;
   height:0px;
   top:50%;
   left:0px;
  }

}

n2s-modal{
  display:block;
  position:relative;
  margin:auto; 
  max-width:80%;
  background-color:white;
  height:auto;
  box-sizing:border-box;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  padding:10px;
  border-radius: 0.5em;


  header{
    margin:-10px -10px 10px -10px;
    padding:5px 10px;
    height:30px;
    border-bottom: 1px solid #CCC;

    .n2s-modal-title{
      padding-right:30px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      font-size: 20px;
      font-weight:normal;
      line-height: 36px;
      color: $mainColor;
    }

    .n2s-modal-close{
      top: 5px;
      right: 5px;
      position: absolute;
    }
  }

  p{
    line-height: 1.4em;
    text-align: justify;

    span.alert{
      font-weight:bold;
      font-size: 90%;  
    }

    span.action-name{
      color: $highlightColor;
      font-weight:bold;
      font-size: 90%;
    }
  }

  p:not(:first-child){
    margin-top:10px;
  }
  
  footer{
    padding:5px 5px 0px 5px;
    border-top: 1px solid #CCC;
    position:absolute;
    bottom:5px;
    left:0px;
    right:0px;
    background-color: white;
    min-height:27px;

    .n2s-modal-footer-buttons{
      float:right;
    }

    .n2s-modal-footer-summary{
      float:left;
      line-height:25px;
    }
  }
}


.modal-exception-content{

  h1{
    font-size: 25px;
    position: absolute;
    top: 120px;
    font-weight: 300;
    color: $alertColor;
    width: 250px;
    left: 30px;
  } 
}

.modal-exception-icon{
  font-size: 110px !important;
  position: absolute;
  right: 10px;
  bottom: 29px;
  opacity: 0.1;
  color: $mainColor;
}

.modal-hint{
  margin-top:20px;
  font-family:Kalam;
  color: #999;
  text-align:center;
}



n2s-modal{

  color: $mainColor;

  n2s-pagination{
    .pagination{
      margin-bottom:10px;
    }
  }

  .modal-h3{
    font-size: 16px;
    color: white !important;
    font-weight: bold;
    line-height: 32px;
    display: inline-block;    
    margin-bottom: 5px;
    width: 100%;
    background-color: #ccc;
    padding: 0px 5px 0px !important;
    box-sizing: border-box;
    border:none !important;
  }

  form.form{
    p.control-errors{
      padding:0;
      margin-top:0px;
    }
    .active-area{
      //text-align:right;
    }
  }

  .modal-avatar{
    @include round-image(96px);
    float:left;
    margin:10px 0px 10px 10px;

    div.icon-avatar{ 
      display: block; 
      width: 100%; 
      border: 0; 
      margin: 0; 
      background-color: $bigIconAvatarBackgroundColor;
      @include border-radius(50%);
      overflow:hidden;
      text-align:center;

      i.icon{
        color: white;
        font-size:65px;
        line-height: 84px; 

        &.icon-warning3{
          top: -6px;
          position: relative;          
        }

        &.icon-paperplane{
          font-size: 55px;
          position: relative;
          left: -5px;
        }
      }
    }    
  }

}