n2s-multiselect {

  .n2s-multiselect-container {
    border-radius: 0.3em;
    background-color: rgba(177, 207, 235, 0.39);
    display: block;
    height: auto;
    padding: 5px;
    min-height: 40px;
    outline:none;
  }

  .n2s-multiselect-item {
    border-radius: 0.3em;
    font-size: 12px;
    background-color: $highlightColor;
    color: white;
    margin: 5px;
    padding: 4px 30px 4px 4px;
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);

    div.close {
      font-family: score;
      font-size: 17px;
      margin-right: 5px;
      position: absolute;
      right: 0px;
      top: 5px;
      line-height: 20px;
      cursor: pointer;
      opacity: 0.2;
      color: black;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
    }
  }

  .n2s-multiselect-candidate div.add {
    font-family: score;
    font-size: 17px;
    margin-right: 5px;
    position: absolute;
    right: 0px;
    top: 5px;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.2;
    color: black;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  }

  .n2s-multiselect-item div {
    &.close {
      &:hover {
        opacity: 0.5;
      }

      &:before {
        content: "\66";
      }
    }

    &.item-icon {
      left: 5px;
      top: 5px;
      height: 20px;
      font-family: score;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      float: left;
      margin-right: 0px 4px;
    }

    &.item-name {
      float: left;
      left: 4px;
      top: 0px;
      max-width: 100%;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;
      padding-left: 4px;
    }

    &.item-detail {
      float: left;
      margin-left: 10px;
      top: 0px;
      background-color: rgba(255, 255, 255, 0.8);
      color: $highlightColor;
      max-width: 100%;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 18px;
      padding: 1px 4px;
      border-radius: 3px;

      &:empty{
        display:none;
      }

    }
  }

  .n2s-multiselect-candidate {
    div {
      &.item-detail {
        float: left;
        margin-left: 10px;
        top: 0px;
        background-color: rgba(255, 255, 255, 0.8);
        color: $highlightColor;
        max-width: 100%;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 18px;
        padding: 1px 4px;
        border-radius: 3px;

        &:empty{
          display:none;
        }
      }

      &.add {
        color: white;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

        &:hover {
          opacity: 0.7;
        }

        &:before {
          content: "\65";
        }
      }
    
      &.item-icon {
        left: 5px;
        top: 5px;
        height: 20px;
        font-family: score;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.5);
        float: left;
        margin-right: 0px 4px;
      }
 
      &.item-name {
        float: left;
        left: 4px;
        top: 0px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
        padding-left: 4px;
      }
    }

    border-radius: 0.3em;
    font-size: 12px;
    background-color: #b1cfeb;
    color: white;
    margin: 5px;
    padding: 4px 30px 4px 4px;
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
  }
}

.n2s-multiselect-search {  
  max-width: 50%;
  min-width: 150px;
  position: relative;
  margin: 2px 5px 5px 5px;
  box-sizing: border-box;
  height: 30px;
  overflow: hidden;
  display:none;
  top:2px;

  &.focused{    
    display:inline-block;
  }

  input {
    font-size: 14px;
    border: 2px solid #99BFE4;
    line-height: 20px;
    outline: 0px !important;
    box-shadow: none !important;
    padding: 0px 30px 0px 30px;
    @include border-radius(0.5em);
    height: 28px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(255,255,255,0.5) !important;
  }

  div {
    &.clear {
      position: absolute;
      right: 5px;
      top: 4px;
      width: 20px;
      height: 20px;
      font-family: score;
      font-size: 20px;
      color: $highlightColor;
      opacity: 0.3;
      cursor: pointer;

      &:before {
        content: "\66";
      }

      &:hover {
        opacity: 1;
      }
    }

    &.icon {
      position: absolute;
      left: 5px;
      top: 5px;
      width: 20px;
      height: 20px;
      font-family: score;
      font-size: 20px;
      color: $highlightColor;

      &:before {
        content:"R";
        /*content: "!";*/
      }
    }
  }
}
