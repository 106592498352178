@CHARSET "UTF-8";

n2s-toggle-button {
  display: inline-block;
  position:relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size:14px;

  &[disabled] {
    opacity: 0.5;
  }

  .toggle-background {

    position: relative;
    border-radius: 2em;
    width: 2.6em;
    /*height: 1.4em;*/
    padding: 0.1em;
    background-color: white;
    -webkit-transition: all 0.2s;

    /* For Safari 3.1 to 6.0 */
    -moz-transition: all 0.2s;

    /* For Safari 3.1 to 6.0 */
    transition: all 0.2s;

    &.toggle-true {
      background-color: $highlightColor; 
      border: 2px solid $light10Color;
    }

    &.toggle-false {
      background-color: $light10HrColor;
      border: 2px solid rgba(0, 0, 0, 0.2);
      
    }
  }

  .toggle-handle {
    position: absolute;
    top:0px;
    border-radius: 2em;
    width: 1.35em;
    height: 1.35em;
    background-color: white;
    margin: 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s;

    /* For Safari 3.1 to 6.0 */
    -moz-transition: all 0.2s;

    /* For Safari 3.1 to 6.0 */
    transition: all 0.2s;
  }

  .toggle-background {
    &.toggle-true .toggle-handle {
      left: 1.45em;
    }

    &.toggle-false .toggle-handle {
      left: 0px;
    }
  }

  &:not([disabled]):active {
    .toggle-background {
      opacity: 0.7;
    }

    .toggle-handle {
      left: 10px;
    }
  }

  &.addition-mark .toggle-background, &.removal-mark .toggle-background {
    width: 20px;
    height: 20px;
    -webkit-transition: none;

    /* For Safari 3.1 to 6.0 */
    -moz-transition: none;

    /* For Safari 3.1 to 6.0 */
    transition: none;
    box-shadow: none;
  }

  &.addition-mark .toggle-background {
    &.toggle-true {
      background-color: #0A93D8;
      border: 2px solid $darkColor;
    }

    &.toggle-false {
      background-color: #fff;
      border: 2px solid #DDD;
    }
  }

  &.removal-mark .toggle-background {
    &.toggle-true {
      background-color: #D8290A;
      border: 2px solid #BA1212;
    }

    &.toggle-false {
      background-color: #fff;
      border: 2px solid #DDD;
    }
  }


  &.addition-mark .toggle-handle, &.removal-mark .toggle-handle {
    display: none;
  }

  /* CHECKMARK */
  &.checkmark .toggle-background {
    width: 20px;
    height: 20px;
    -webkit-transition: none;

    /* For Safari 3.1 to 6.0 */
    -moz-transition: none;

    /* For Safari 3.1 to 6.0 */
    transition: none;
    box-shadow: none;
  }

  &.checkmark .toggle-background {
    &.toggle-true {
      background-color: white;
      border: 2px solid $darkColor;

      .toggle-handle{
        left: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
        background-color: #0A93D8;
      }
    }

    &.toggle-false {
      background-color: white;
      border: 2px solid $darkColor;

      .toggle-handle{
        left: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
        background-color: #0A93D8;
        opacity:0;
      }
    }
  }

  &.small{
    font-size:10px;
  }

}

n2s-toggle-button.alert {
  .toggle-background {
    &.toggle-true {
      background-color: $lightAlertColor;
      border: 2px solid $alertColor;
    }
  }
}

n2s-toggle-button.danger {
  .toggle-background {
    &.toggle-true {
      background-color: $lightDangerColor;
      border: 2px solid $dangerColor;
    }
    
    &.toggle-false {
      background-color: $light40DangerColor;
      border: 2px solid $lightDangerColor;      
    }
  }
}


n2s-text-toggle{

  div.n2s-text-toggle{
    display:inline-block;
    
    font-size:12px;
    font-weight:bold;
    border-radius:16px;
    background-color:rgba(0,0,0,0.1);
    padding: 4px;

    width:27px;
    height:27px;
    box-sizing:border-box;
    text-align:center;
    cursor:pointer;
    opacity:0.5;
    margin:2px;

    &:hover{
       background-color: $highlightColor;
       color: white;
       box-shadow: 0px 2px 2px rgba(0,0,0,0.5);       
    }

    &.toggle-true{
       border: 2px solid $highlightColor;
       background-color: $highlightColor;
       color: white;
       opacity:1;
       cursor:default;
    }

    &.toggle-false{
      border: 2px solid $light20Color;
      background-color: $light40Color;
      color: $light20Color;
    }
  }

}
