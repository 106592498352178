@CHARSET "UTF-8";

n2s-pagination {
  display: block;

  .pagination {
    margin-top: 10px;
    margin-right: 5px;
  }
}

/* Important: theses styles are built on top of bootstrap's pagination item, so they are tightly bounded.*/

.pagination .pagination-count {
  float: left;
  text-align: left;
  line-height: 35px;
  margin-left: 5px;

  div.item-count {
    
    display:inline-block;

    span{
      font-weight: bold;
    }

  }

  i.list-refresh {
    cursor: pointer;
    opacity: 0.5;
    font-weight: bold;
    position: relative;
    top: 3px;

    &:hover {
      opacity: 1;
      color: rgb(0, 96, 174);
    }
  }
}
