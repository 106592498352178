@charset "UTF-8";
@import "globals.scss";
@import 'fonts.scss';

/* SASS FORM STYLES */

form.form{

    h3{
        font-size: 16px;
        color: #777;
        font-weight: bold;
        line-height: 32px;
        display: inline-block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 5px;
        padding: 0px;
        width: 100%;

        &.sub {
            font-size: 14px;
            color: #777;
            font-weight: bold;
            line-height: 24px;
            display: inline-block;
            border-bottom: none;
            margin-bottom: 5px;
            padding: 0px;
            width: 100%;
        }
    }

    h4{
        text-align: center;
        font-weight: 300;
        font-size: 12px;
        margin-top: 10px;
        text-transform: uppercase;
    }

    .contentfiltering {
        .label {
            display: table-cell;
            width: 30%;
        }
        .active-area {
            width: 70%;
        }
    }

    .fieldgroup{

        border:none;
        box-sizing:border-box;
        width:100%;
        display: table;

        &.fixed{
            table-layout:fixed;
        }

        .controls{

            box-sizing: border-box;
            /*clear: both;*/
            width:100%;
            padding: 5px 0px;
            display:table-row;

            &.composite {
                label {
                    vertical-align: middle;
                }
                .active-area {
                    vertical-align: top;
                }
            }

            &.modules {
                .active-area {
                    width: 70%;
                }
            }

            label {
                font-size:14px;
                color: $light20MainColor;
                vertical-align: text-top;
                /*float:left;*/
                line-height:23px;
                width:100%;
                display:table-cell; // NUEVO
                width:auto; // NUEVO

                white-space:nowrap;
                text-overflow:ellipsis;
                overflow:hidden;
                min-width: 150px;

                &.small{
                    font-size:12px;
                }

                &.top{
                    position:relative;
                    top:-22px;
                }

            }

            .active-area{
                display: table-cell;
                padding: 5px;
                text-align:right;
                color: $mainColor;
                width: 100%;

                & > div{
                    text-align:left;
                }

                & label{
                    text-align:left;
                    display: inline;
                }

                & > input[type=text], & > input[type=password], & > input[type=email], & > input[type=url], & > input[type=number]{
                    /*float:right;*/
                    max-width:100%;

                    background-color:rgba(192,192,192,0.2);
                    font-size:14px;
                    border-radius: .3em;
                    border:none;
                    padding:.3em;
                    box-sizing:border-box;

                    &:hover{
                        background-color: rgba(0, 0, 0, 0.1);
                    }

                    &:focus{
                        outline:none;
                        background-color: $light40Color;
                        color: $highlightColor;
                    }

                    &.ng-invalid{
                        background-color:$inputAlertBackground;
                        color:$alertColor;

                        &:focus{
                            background-color:$lightAlertColor;
                        }
                    }

                    &.fullwidth{
                        width:100%;
                    }

                }

                .fullwidth{
                    text-align:left;
                }

                p.control-errors{
                    width:100%;
                    padding: 0px;
                    margin-top: 0px;
                    /*font-style: italic;*/
                    font-family: Kalam, Lato;
                    color:$alertColor;
                    text-align: left;
                }
            }
        }


        &.fixed-labels{
            .controls{
                & > label{
                    display:table-cell;
                    width: 37.25%;
                }
            }
        }

    }

    p.control-errors{
        width:100%;
        padding: 5px;
        margin-top: 4px;
        /*font-style: italic;*/
        font-family: Kalam, Lato;
        color:$alertColor;
    }
}


.n2s-form-unit{
    margin-left:10px;
    color: #777;
}

h3.collapsable{

    cursor:pointer;

    &:before{
        content: '\7a';
        font-family: score;
        font-size: 22px;
        position: relative;
        top: 4px;
        opacity: 0.5;
        margin-left: -5px;
    }

    &:hover{
        &:before{
            color: $highlightColor;
            opacity:1;
        }
    }

    &.collapsed{
        &:before{
            content: '\78';
        }
    }

    &:not(.collapsed){
        & + *{
            display:none;
        }
    }

    span.tip{
        margin-left: 20px;
        font-family: Kalam;
        color: $light20MainColor;
    }

}

.readonly-veil{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    cursor: not-allowed;
    z-index: 10;
    height: 100000px;
}


.radio-btn {
    background-color: $controlBackground;
    color: $highlightColor;
    padding:2px 7px;
    cursor:pointer;
    line-height:25px;


    &:hover{
        color: $trans50Color;
    }

    &.selected{
        color: #FFFFFF;
        background-color: $highlightColor;
        cursor:default;
    }


    &:first-child{
        border-top-left-radius: 0.3em;
        -webkit-border-top-left-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
        -webkit-border-bottom-left-radius: 0.3em;
    }

    &:last-child{
        border-top-right-radius: 0.3em;
        -webkit-border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
        -webkit-border-bottom-right-radius: 0.3em;
    }
}


.form-hint{
    padding: 10px;
    @include border-radius(.5em);
    background-color: #ddd;
    color: var(--main-clr);
    font-size:13px;
    margin: 10px 0px;
    font-family:Kalam;
    text-align: justify;

    a{
        font-family: $mainFont;
    }

}


/*
  UPDATE ON ENTER
*/
[update-on-enter]{
    padding-right:50px !important;
    box-sizing:border-box;
}

.updatable{

}

.n2s-update-input-ok{
    position: absolute;
    right: 28px;
    top: 6px;
    font-size: 17px;
    display:none;
    z-index:2;
}

.n2s-update-input-cancel{
    position: absolute;
    right: 4px;
    top: 5px;
    font-size: 21px;
    display:none;
    z-index:2;
}

.n2s-update-input-wrapper{
    position:relative;
}

.updatable + .n2s-update-input-ok{
    display:block;
    opacity:0.5;
    cursor:pointer;
    color:$highlightColor;
}

.updatable + .n2s-update-input-ok + .n2s-update-input-cancel{
    display:block;
    opacity:0.5;
    cursor:pointer;
    color:$highlightColor;
}


.updatable.ng-invalid + .n2s-update-input-ok{
    color:$alertColor;
}

.updatable.ng-invalid + .n2s-update-input-ok + .n2s-update-input-cancel{
    color:$alertColor;
}

.n2s-update-input-ok:hover{
    opacity:1;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
}

.n2s-update-input-cancel:hover{
    opacity:1 !important;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
}

