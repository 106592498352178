n2s-bigselect{
  display: inline;


  &.ng-invalid{

    .n2s-bigselect-sample{
      
      &:after{
        opacity: 1;
        color: $alertColor;
      }

      color:$alertColor;
      background-color:$inputAlertBackground;
      border-radius: 5px;
    }  
  }


}

.n2s-bigselect-popup-wrapper{
  padding:5px;
}

.n2s-bigselect-table{
  tr:hover{
    td{
      background-color: $light40Color;
    }
    cursor:pointer;
  }
}

.n2s-bigselect-sample{
  font-size:1em;
  color:$mainColor;
  cursor:pointer;
  border-bottom: 1px solid transparent;
  text-align: left;
  position:relative;
  line-height: 25px;
  height:25px;
  padding-left:5px;
  padding-right:20px;
  box-sizing:border-box;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y:hidden;
  text-overflow: ellipsis;

  &:after{
    font-family: 'score';
    content:'\78';
    top: 2px;
    right:5px;
    opacity:0.5;
    margin-left:10px;
    position:absolute;
  }

  &:hover{
    &:after{
      opacity: 1;
      color: $highlightColor;
    }
    color:$highlightColor;
    background-color: $light40Color;
    border-radius: 0.2em;
    //border-bottom: 1px dashed $highlightColor;
  }
}

[popup-id=bigSelect]{
  z-index: 110;
  //max-width:470px;
  min-width:250px;
}
