n2s-colorpicker{
  display: inline-block;
}

.n2s-fontpicker-sample{
  font-size:18px;
  color:$highlightColor;
  cursor:pointer;
  border-bottom: 1px solid transparent;
  width:100%;  

  &:after{
    font-family: 'score';
    content:'\78';
    position:relative;
    top: 2px;
    opacity:0.5;
    margin-left:10px;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

[popup-id=fontPicker]{
  z-index: 110;
  width:470px;
}

.splash-font-selector {    

  .splash-font-style-selector{
    border-bottom:2px solid $hrMediumColor;
    padding-bottom:5px;
    margin:5px;
  }

  .splash-font-sample{
    height:40px;
    font-size:18px;
    padding:5px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    color: $mainColor;
  }


  div.splash-font{
    font-size:18px;
    color: $mainColor;
    margin: 3px 0px;
    padding:0px 5px;
    display:inline-block;
    cursor:pointer;
    width: 140px;
    overflow: hidden;

    &:hover{
      color: $light20Color;
    }

    &.selected{
      color: $highlightColor;
    }
  }
}

.n2s-font-picker-ok{
  float:right;
  margin:0px;
}
