.n2s-searchbox-container {
  position:relative;

  div.icon {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    font-family: score;
    font-size: 20px;
    color: $light20Color;

    &:before {
      content: "R";
      opacity:0.5;
    }
  }

  &.searching div.icon {
    &:before {
      content: "i";
    }

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: spin-counterclockwise 1s linear infinite;
    -moz-animation: spin-counterclockwise 1s linear infinite;
    -o-animation: spin-counterclockwise 1s linear infinite;
    animation: spin-counterclockwise 1s linear infinite;
  }
}



n2s-filter-input, n2s-searchbox, n2s-searchinput {

  display: inline-block;
  cursor:pointer;


  &[disabled] {
    opacity: 0.5;
  }


  input {
    font-size: 14px;
    border: 2px solid #e0e0e0 !important;
    line-height: 20px !important;
    outline: 0px !important;
    padding: 0px 30px 0px 30px !important;

    
    box-shadow:none !important;
    box-sizing:border-box !important;
    @include border-radius(0.3em !important);
    @include input-placeholder{
      font-family:Lato;
    }

    height: 28px;
    width: 100%;
    min-width: 100px;
    background-color: transparent;
    color: $highlightColor !important;

    &:hover, &:focus{
      background-color: $light40Color !important;
      border: 2px solid $light40Color !important;
    }
  }

  div.clear {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    font-family: score;
    font-size: 20px;
    color: $highlightColor;
    opacity: 0.3;
    cursor: pointer;

    &:before {
      content: "\66";
    }

    &:hover {
      opacity: 1;
    }

    &[disabled] {
      opacity: 0;
    }
  }
}

::-ms-clear{display:none;}
_:-ms-fullscreen, :root n2s-filter-input input, n2s-searchbox input, n2s-searchinput input { padding-top: 4px !important; }


