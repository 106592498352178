@CHARSET "UTF-8";

n2s-datepicker {

  display:inline-block;
  width:150px;
  cursor:pointer;

  &[disabled] {
    opacity: 0.5;
  }

  div.n2s-datepicker-trigger{
    position:relative;
  }

  input.n2s-datepicker-input {
    font-size: 14px;
    border: 2px solid #99BFE4 !important;
    outline: 0px !important;
    box-shadow: none !important;
    padding: 3px 30px 2px 30px !important;
    @include border-radius(0.3em);
    @include box-sizing(border-box);
    font-family: Lato;
    height: 25px;
    width: 100%;
    color: $highlightColor;
    min-width: 100px;
  }

  div.icon {
    position: absolute;
    left: 8px;
    top: 3px;
    width: 20px;
    height: 18px;
    font-family: score;
    font-size: 17px;
    color: $highlightColor;
    line-height:20px;

    &:before {
      content: "[";
    }
  }


  div.clear {
    position: absolute;
    right: 2px;
    top: 3px;
    width: 20px;
    height: 20px;
    font-family: score;
    font-size: 18px;
    color: $highlightColor;
    opacity: 0.3;
    line-height:20px;

    &:before {
      content: "\66";
    }

    &:hover {
      opacity: 1;
    }

    &[disabled] {
      opacity: 0;
    }
  }

  .n2s-datepicker-popup{
      position:absolute;
      background-color: white;
      box-shadow: 0px 2px 15px rgba(0,0,0,0.5);
      z-index:10;

      .n2s-popup-arrow {
      position: absolute;

      /*display: none;*/
      width: 10;
      border-color: rgba(255, 0, 0, 0);
      border-style: dotted;

      &:after {
        position: absolute;

        /*display: none;*/
        width: 10;
        border-color: rgba(255, 0, 0, 0);
        border-style: dotted;
        border-width: 10px;
        width: 0px;
        height: 0px;
        content: "";
      }

      border-width: 11px;
      width: 0px;
      height: 0px;
      }

      &[arrow=bottom] .n2s-popup-arrow {
          bottom: -11px;
          left: 50%;
          margin-left: -11px;
          border-top-color: #999;
          border-top-color: rgba(0, 0, 0, 0.25);
          border-bottom-width: 0;

          &:after {
            bottom: 1px;
            margin-left: -10px;
            border-top-color: #ffffff;
            border-bottom-width: 0;
          }
      }

      &[arrow=left] .n2s-popup-arrow {
          top: 50%;
          left: -11px;
          margin-top: -11px;
          border-right-color: #999;
          border-right-color: rgba(0, 0, 0, 0.25);
          border-left-width: 0;

          &:after {
            bottom: -10px;
            left: 1px;
            border-right-color: #ffffff;
            border-left-width: 0;
          }
      }

      &[arrow=top] .n2s-popup-arrow {
          top: -11px;
          left: 50%;
          margin-left: -11px;
          border-bottom-color: #999;
          border-bottom-color: rgba(0, 0, 0, 0.25);
          border-top-width: 0;

          &:after {
            top: 1px;
            margin-left: -10px;
            border-bottom-color: #ffffff;
            border-top-width: 0;
          }
      }

      &[arrow=right] .n2s-popup-arrow {
          top: 50%;
          right: -11px;
          margin-top: -11px;
          border-left-color: #999;
          border-left-color: rgba(0, 0, 0, 0.25);
          border-right-width: 0;

          &:after {
            right: 1px;
            bottom: -10px;
            border-left-color: #ffffff;
            border-right-width: 0;
          }
      }
    }
}
