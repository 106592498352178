@charset "UTF-8";


.overlay{
  color: white;
  min-height: 390px;
  z-index: 1500;
  -webkit-perspective: 2110px;
  -webkit-perspective-origin: 50% 50%;
  -webkit-transition: all 0.3s;
  -webkit-transition-timing-function: ease;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;

  &.closing {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }

  &.closed {
    display: none;
    -webkit-transform: scale(1.2);
  }

  .icon-btn{
    color: white;
    border-color: white;
    margin-bottom: 5px;
  }

  .icon-btn + br + span{
    font-weight: normal;
    //font-style:italic;
  }


  &.removing{
    n2s-image:not(.editing){
      opacity:0.5;
      
      div.n2s-image-container.resolved{
        cursor: not-allowed;  
      }

    }
  }
}

.gallery-image {
  float: left;
  margin: 5px;
  width: 118px;
  height: 89px;
}

.smooth {
  -webkit-transition: all 0.5s;
  -webkit-transition-timing-function: ease;
}

.blurry {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);

  -webkit-filter: grayscale(100%);
  -moz-filter:    grayscale(100%);
  -ms-filter:     grayscale(100%);
  -o-filter:      grayscale(100%);
  opacity:0.4;
}

/* SCALE 1 */
@media (min-height: 285px) {
  .scale1 .gallery {
    height: 99px;
  }
}

@media (min-height: 384px) {
  .scale1 .gallery {
    height: 198px;
  }
}

@media (min-height: 483px) {
  .scale1 .gallery {
    height: 297px;
  }
}

@media (min-height: 582px) {
  .scale1 .gallery {
    height: 396px;
  }
}

@media (min-height: 681px) {
  .scale1 .gallery {
    height: 495px;
  }
}

@media (min-height: 780px) {
  .scale1 .gallery {
    height: 594px;
  }
}

/* SCALE 2 */

@media (min-height: 326px) {
  .scale2 .gallery {
    height: 178px;
  }
}

@media (min-height: 545px) {
  .scale2 .gallery {
    height: 356px;
  }
}

@media (min-height: 723px) {
  .scale2 .gallery {
    height: 534px;
  }
}

@media (min-height: 901px) {
  .scale2 .gallery {
    height: 712px;
  }
}

.gallery-content {
  -webkit-transition: top 0.5s;
  -webkit-transition-timing-function: ease;
}

.page-selector {
  border-radius: 50%;
  background-color: white;
  opacity: 0.3;
  margin: 3px;
  width: 10px;
  height: 10px;
  display: inline-block;
  -webkit-transition: opacity 0.5s;
  -webkit-transition-timing-function: ease;
  transition: opacity 0.5s;
  transition-timing-function: ease;
  cursor: pointer;
}

.selected-page {
  opacity: 1;
}

.overlay .btn-icon-hollow {
  background-color: transparent !important;
  color: white !important;
  border-color: white !important;
}

@-webkit-keyframes editing {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-moz-keyframes editing {
  0% {
    -moz-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -moz-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -moz-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -moz-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -moz-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -moz-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -moz-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -moz-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -moz-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -moz-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -moz-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-o-keyframes editing {
  0% {
    -o-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -o-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -o-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -o-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -o-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -o-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -o-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -o-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -o-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -o-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -o-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes editing {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

n2s-image {
  &.editing .n2s-image-container {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: editing 1s linear infinite;
    -moz-animation: editing 1s linear infinite;
    -o-animation: editing 1s linear infinite;
    animation: editing 1s linear infinite;
  }

  &.removing .n2s-image-container {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: bounceOut 0.7s linear;
    -moz-animation: bounceOut 0.7s linear;
    -o-animation: bounceOut 0.7s linear;
    animation: bounceOut 0.7s linear;
  }

  &.uploaded .n2s-image-container {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: tada 0.7s linear;
    -moz-animation: tada 0.7s linear;
    -o-animation: tada 0.7s linear;
    animation: tada 0.7s linear;
  }
}

#CMSOverlay {  
  position:fixed;
  margin:0px;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  min-height:230px;
  background-color:rgba(0,0,0,0.9);

  &.scale2 .gallery-image{
    width: 225px;
    height: 168px;
    margin: 5px 15.5px;
  }
}

#dropzone {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}


#CMSOverlay.circle-format{
  
  & n2s-image .n2s-image-container {
    background-size: cover;
    border-radius:50%;
  }

  & .gallery-image{
    width: 90px !important;
    height: 90px;
    margin: 5px 19px;    
  }

 &.scale2 .gallery-image{
    width: 168px !important;
    height: 168px;
    margin: 5px 44px;
  }
  
}

.cms-loading-view{
  position:relative;
  color:white;
  
  &.loaded{
    display:none;
  }

  .cms-loading-message{
    position:absolute;
    top:50%;
    margin-top:-124px;
    text-align:center;
    width:100%;

    h1{
      font-size: 40px;
      font-weight: 300;
      color: inherit;
      margin-top:30px;
    }

  }

  .cms-loading-icon{
    color: inherit;
    font-size:200px;    
  }

}

.overlay-exception-view{
  position:fixed;  
  left:0px;
  width:100%;
  top:0px;
  bottom:150px;


  .exception-view-wrapper{
    position:relative;
    top: 40px;
    height:100%;
    width:1024px;
    margin: 0px auto;
  }
  
  .exception-view-icon{
    font-size:330px;
    color:rgba(255,255,255,0.5);
    position:absolute;
    bottom:0px;
    right:10px;

    font-family: 'score';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;      
  }


  .exception-view-content{
    z-index:2;
    position:absolute;
    left:50px;
    bottom:50px;
    width:50%;

    h1{
      font-size:40px;
      font-weight:300;
      color:white;
    }

    h2{
      font-size:30px;
      font-weight:300;
      color:white;
    }

    p{     
      text-align:justify;
      color: white;
      margin: 15px;
    }

    hr{
      height: 2px;
      border: none;
      background-color: rgba(255,255,255,0.5);
    }

    .right{
      position:absolute;
      right:0px;
    }
  }
}


.overlay-popup{
  background-color: rgb(53,53,53);
  
  .n2s-popup-arrow.arrow-top:after {
    border-bottom-color: rgb(53,53,53);
  }

  input[type="checkbox"] + label:before{
    background-color: transparent;
    color: rgb(173,173,173);
  }

  input[type="checkbox"] + label:after{
    color:white;
  }

  input[type="checkbox"]:focus + label{
    color:white;
  }

  a{
    text-decoration: none;
  }

  border-color: #222;














}

.overlay-header{
  width:100%;
  height:30px;
  background-color:black;
  text-align: center;
  display:table;
  padding:10px 0px;

  
  .overlay-header-row{
    display:table-row;
    padding:0px;
    width:100%;

    .overlay-header-title{
      display:table-cell;
      text-align: left;
      font-size:20px;
      font-weight:100;
      line-height:40px;
      vertical-align: middle;
      padding-left:10px;
      padding-right:20px;
      border-left: 1px solid var(--main-clr);
    }

    .overlay-header-format{
      display: table-cell;
      width: 200px;
      padding-right: 20px;     
    }

    .overlay-header-icons{        
      display:table-cell;                
      vertical-align: middle;
      text-align:right;
      width:120px;
      padding-right:10px;
      border-left: 1px solid var(--main-clr);

      i{
        font-size:28px;
        margin:0px 5px;
        position:relative;
        top:3px;
        cursor:pointer;
        opacity:0.8;

        &:hover{
          opacity:1;
        }
      }

      span{
        font-size:28px;
        font-weight:bold;
        opacity:0.8;
        cursor:pointer;
        &:hover{
          opacity:1;
        }
      }
    }


    .overlay-header-zoom{        
      display:table-cell;                
      vertical-align: middle;
      text-align:right;
      width:40px;
      padding-right:10px;
      border-left: 1px solid var(--main-clr);

      span{
        font-size:28px;
        font-weight:bold;
        opacity:0.8;
        cursor:pointer;
        &:hover{
          opacity:1;
        }
      }
    }

  }
}



.overlay{
  .exception-full-frame{
  
    .exception-frame-content{
  
      h2{
        color:white;
      }
  
      p{     
        color: white;
      }
  
    }
  
    .exception-frame-icon{
      color: white;
      text-shadow: unset;
    }
  
    .add-item-btn{
      margin-top:20px;
      max-width: 500px;
      color: white;
      border-color:white;
    }  
  }
  
}







@import '../js/Angular/Directives/AvatarEditor/AvatarEditor.scss';

