@CHARSET "UTF-8";

n2s-weekdaycontrol {
  display: inline;

  ul {
    margin: 0px;
    border-radius: 2em;
    background-color: $light20Color;
    padding: 4px 7px;
    width: 198px;

    li {
      display: inline-block;
      padding: 2px;
      line-height: 20px;
      text-decoration: none;
      border-left-width: 0;
      opacity: 1;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      border-radius: 2em;
      margin: 2px;
      width: 20px;
      text-align: center;
      color: #DDD;
      background-color: white;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &.disabled ul li {
    opacity: 1;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  ul li.selected {
    background-color: $highlightColor;
    color: white;
  }
}
