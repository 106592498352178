n2s-unit-based-input{

    display:inline-table;    

    input{
        text-align:right;
        margin-right:5px;
        display:table-cell;
    }

    n2s-select{
        //width:60px;

        i.icon{
            //display:none;
        }

        .select-control{

          &.maccaching, &.hours, &.time, &.days, &.dailyhours, &.maxtime {
            width: 58px;
          }
            .select-selection{
                text-align: center; 
                clear:both;               
            }
        }
    }

    &.small{
        input{
            font-size:12px;
        }

        n2s-select{
          font-size:12px;
            i.icon {
              font-size: 1.2em;

              &.icon-arrow-down5{
                padding-top:0.2em;
              }
            }            
        }

        &[type=traffic]{
           n2s-select{
            width:42px;
           } 
        }

        &[type=time]{
           n2s-select{
            width:60px;
           } 
        }

    }

    &[disabled]{
        opacity:1 !important;
    }

    &.ng-invalid{
        input{
            background-color:$inputAlertBackground;
            color:$alertColor;

            &:focus{
               background-color:$lightAlertColor;
               color:$alertColor;
            }
        }
    }
}


