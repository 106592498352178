n2s-timerange {
  display: inline-block;
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div {
  &.n2s-timerange-ruler {
    width: 100%;
  }

  &.n2s-timerange-rail {
    width: 100%;
    height: 20px;
    position: relative;
  }
}

.n2s-timerange-cursor {
  display: inline-block;
  border: 10px solid transparent;
  width: 0px;
  height: 0px;
  position: relative;
  opacity: 0;
  cursor: pointer;

  &.from {
    top: 10px;
    border-top: 10px solid $highlightColor;
  }

  &.to {
    border-bottom: 10px solid $highlightColor;
    top: -10px;
  }

  &.select {
    opacity: 1;
  }
}

.n2s-timerange-hour {
  display: inline-block;
  width: 14px;
  font-size: 11px;
  color: white;
  background-color: $light40Color;
  padding: 0px 2px;
  margin: 1px;
  text-align: center;
  border-radius: 0.2em;
  font-weight: bold;

  &.active {
    color: white;
    background-color: $highlightColor;
  }
}
