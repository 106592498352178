n2s-spinner{
  @include user-select(none);
  display:inline-block;
  text-transform:uppercase; 
  color: $highlightColor;
  background-color: #B1CFEB;
  border-radius: 0.5em;
  padding: 0px 30px; 
  margin-right: 5px;
  line-height:25px;
  position:relative;
  font-size: 11px;
  box-sizing: border-box;
  white-space: nowrap;

  div.n2s-spinner-previous-option{
    position: absolute;
    height: 18px;
    vertical-align: middle;
    padding-right: 5px;
    text-align: center;
    left: 5px;
    top: 50%;
    line-height: 0;
    font-size: 0px;
    opacity:0.5;
    cursor: pointer;

    &:after {
      width: 0;
      height: 0;
      border-right: 7px solid $highlightColor;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      content: "";
    }
  }

  div.n2s-spinner-next-option{
    position: absolute;
    height: 18px;
    vertical-align: middle;
    padding-right: 1px;
    text-align: center;
    right: 5px;
    top: 50%;
    line-height: 0;
    font-size: 0px;
    opacity:0.5;
    cursor: pointer;


    &:after {
      width: 0;
      height: 0;
      border-left: 7px solid $highlightColor;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      content: "";
    }
  }

  &:hover{
    div.n2s-spinner-previous-option, div.n2s-spinner-next-option{
      opacity:1;
    }
  }

  &[disabled]{
   
    opacity:0.5;

    div.n2s-spinner-previous-option, div.n2s-spinner-next-option{
      display:none;
    }
  }

}