@CHARSET "UTF-8";

div.n2s-radiocontrol {
  display: inline-block;
  text-align: left;
  margin: 5px 10px 5px 0px;

  span {
    font-size: 16px;
    color: #777;
    font-weight: normal;
    margin: 0px 10px 0px 2px;
    padding: 5px 10px 5px 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    cursor: pointer;
    background-color: #eee;

    &:first-child {
      margin-left: 0px;
    }

    &:hover {
      color: #fff;
      background-color: $light20Color;
    }

    &.selected {
      color: white;
      background-color: $highlightColor;
      font-weight: normal;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }

    &.addradio {
      background-color: var(--main-clr);
      color: #fff;
      border-radius: 2em;
      opacity: 0.2;
      transition: background-color 0.5s;
    }
  }
}
