n2s-inline-input {
  display: inline;
  border-radius: 0.2em;
  padding: 0.2em 2em 0.2em 0.2em;
  position: relative;
  height: 1.3em;
  line-height: 1em;

  input {
    border: none !important;
    font: inherit;
    background-color: transparent;
    outline: none;
    width: 80%;
    display: none;
  }

  div.inline-text-container {
    display: inline-block;
    width: 100%;
    cursor: pointer;

    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      height: 1.3em;
      padding-top: 0.15em;
    }
  }

  input:focus {
    outline: none;
    box-shadow: none;
  }

  &.editing {
    background-color: $light40Color;
    color: $highlightColor;

    input {
      display: inline-block;
      color: $highlightColor;
    }

    div.inline-text-container {
      display: none;
    }
  }

  i {
    &.icon-pencil, &.icon-ok, &.icon-cancel-circle {
      position: absolute;
      top: 0.4em;
      right: 0.8em;
      color: $highlightColor;
      opacity: 0;
      cursor: pointer;
      font-size: 1em;
    }
  }

  &:hover i.icon {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  i {
    &.icon-cancel-circle, &.icon-ok {
      display: none;
    }
  }

  &.editing i {
    &.icon-cancel-circle {
      display: block;
      opacity: 0.3;
      right: 2em;
    }

    &.icon-ok {
      display: block;
      opacity: 0.3;
    }

    &.icon-pencil {
      display: none;
    }
  }

  &:hover {
    background-color: $light40Color;
  }

  div.updating {
    display: none;
  }

  &.updating {
    i {
      &.icon-pencil, &.icon-ok, &.icon-cancel-circle {
        display: none;
      }
    }

    div.updating {
      display: block;
      opacity: 1;
      width: 1em;
      height: 1em;
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      vertical-align: middle;
      text-align: center;
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-animation: spin-counterclockwise 1s linear infinite;
      -moz-animation: spin-counterclockwise 1s linear infinite;
      -o-animation: spin-counterclockwise 1s linear infinite;
      animation: spin-counterclockwise 1s linear infinite;

      i {
        font-size: 1em;
        margin: 0px;
        position: absolute;
        top: 0.115em;
        left: 0px;
        opacity: 0.7;
      }
    }
  }

  &.error {
    background-color: rgba(255, 128, 0, 0.5);
  }
}

header n2s-inline-input {
  display: inline-block;
  font-weight: normal;
  font-size: 30px;
  margin: 0px;
  width: 480px;
  padding: 0.1em 2em 0.1em 0.1em;
  color: var(--main-clr);

  input {
    width: 80%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.editing {
    background-color: $light40Color;
  }

  i.icon-pencil {
    color: rgba(0, 0, 0, 0.4);
  }
}
