@import "commons.scss";
@import 'fonts.scss';

html {
    height: 100%;
    width: 100%;
    /*min-width: $minBodyWidth;*/
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: $mainFont;
    font-size: 14px;
    min-width: $minBodyWidth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:$backgroundColor;
    overflow:hidden;
}


* {

    margin: 0;

    &:before, &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.hpad{
    padding-left: $hPadding;
    padding-right: $hPadding;
    height: calc(100% - #{$pageHeaderHeight});
    overflow-y: auto;
    width: calc(100% - #{$hPadding + $hPadding});
}

.thin-hpad{
    padding-left: calc($hPadding/2);
    padding-right: calc($hPadding/2);
}

.small-font{
    font-size:12px;
}


[disabled] {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

hr{
    height:0px;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    border-left:none;
    border-right:none;
    margin: 5px 0px;

    & + hr {
        display:none;
    }

}

ul {
    padding:0;
}

a{
    color: $highlightColor;
}

span{
    &.alert{
        color: $alertColor;
    }

    &.highlight{
        color: $highlightColor;
    }
}

img[src='/cms/']{
    display:none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
}

input[type=text], input[type=number], input[type=password], input[type=email], input[type=url]{

    background-color:#EEE;
    font-size:14px;
    border-radius: 0.3em;
    border:none;
    padding:0.3em;
    font-family:Lato;
    color: $mainColor;

    &:hover{
        background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus{
        outline:none;
        background-color: $light40Color;
        color: $highlightColor;
    }

    &.ng-invalid{
        background-color:$inputAlertBackground;
        color:$alertColor !important;

        &:focus{
            background-color:$lightAlertColor;
        }
    }

    &[readonly]{
        background-color: transparent !important;
        color: $mainColor !important;
    }


    &.small{
        font-size:12px;
    }

    @include input-placeholder {
        color: #bfbfbf;
        font-style: italic;
    }
}

textarea{
    background-color:rgba(192,192,192,0.1);
    font-size:14px;
    border-radius: 0.3em;
    border:none;
    padding:4px;

    @include input-placeholder {
        color: #bfbfbf;
        font-style: italic;
    }


    &:hover{
        background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus{
        outline:none;
        background-color: $light40Color;
        color: $highlightColor;
    }

    &.ng-invalid{
        background-color:$inputAlertBackground;
        color:$alertColor;

        @include input-placeholder {
            color: $alertColor;
            font-style: italic;
        }

        &:focus{
            background-color:$lightAlertColor;
        }
    }
}


input[type="checkbox"]{
    //position: absolute;
    left: -9999px;
}

input[type="checkbox"] + label{
    position: relative;
    padding-left: 2em;
    cursor: pointer;
    font-size:14px;
    display:inline !important;

    &:before{
        content: '';
        position: absolute;
        left:0px;
        top:-0.25em;
        padding:.3em;
        width: 1.75em;
        height:1.75em;
        background: $controlBackground;
        border-radius: .2em;
    }

    &:after{
        content: '_';
        font-family:'score';
        position: absolute;
        top: 0em;
        left: 0.07em;
        font-size: 1.5em;
        line-height: 0.9;
        transition: all .2s;
        color: $highlightColor;
    }

    &.small{
        font-size:12px;
        line-height:22px;
    }

    &.left-label{
        min-height: 26px;
        float: left;

        &:before{
            top:0;
        }

        &:after{
            top:0.15em;
        }
    }
}

input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;

}

input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

input[type="checkbox"]:indeterminate + label:after {
    content: "\e920";
    opacity: 1;
}


input[type="checkbox"]:disabled + label {
    opacity:0.5;
}

input[type="checkbox"]:focus + label {
    color: $highlightColor;
}


h3 {
    input[type="checkbox"] + label{
        position:relative;
        line-height: 20px;
        float: right;
        top:10px;
        margin-bottom:0px;
        font-weight: normal;
        font-size: 12px;
    }

    input[type="checkbox"]:focus + label {
        color: $mainColor;
    }
}


input[type="radio"]{
    //position: absolute;
    left: -9999px;
}

input[type="radio"] + label{
    position: relative;
    padding-left: 2em;
    cursor: pointer;
    font-size:14px;

    &:before{
        content: '';
        position: absolute;
        left:0px;
        top:-0.25em;
        padding:0.3em;
        width: 1.75em;
        height:1.75em;
        background: $controlBackground;
        border-radius: 50%;
    }

    &:after{
        content:'';
        position: absolute;

        font-size: 1em;
        line-height: 0.9;
        width: 1em;
        height:1em;

        top: 0.05em;
        left: 0.3em;
        font-size: 1.1em;
        line-height: 0.9;
        transition: all .2s;
        background-color: $highlightColor;
        border-radius: 50%;
    }


    &.small{
        font-size:12px;
        line-height:22px;
    }

}

input[type="radio"]:not(:checked) + label:after {
    opacity: 0;

}

input[type="radio"]:checked + label:after {
    opacity: 1;
}


input[type="radio"]:disabled + label {
    opacity:0.5;
}

input[type="radio"]:focus + label {
    color: $highlightColor;
}


h3 {
    input[type="radio"] + label{
        position:relative;
        line-height: 20px;
        float: right;
        top:10px;
        margin-bottom:0px;
        font-weight: normal;
        font-size: 12px;
    }

    input[type="radio"]:focus + label {
        color: $mainColor;
    }
}








input[n2s-number], input[n2s-integer]{
    text-align:right;
}

.grayscale {
    @include filter(grayscale(100%));
}

.hidden{
    display:none;
}

.right{
    text-align:right;
}


.app-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: -$footerHeight;
    overflow:hidden;

    opacity:0;

    /* min-width: $minBodyWidth;*/
}

/** HEADER **/

.app-header {
    background-color: $navBackground;
    width:100%;
    min-width: $minBodyWidth;
    /*box-shadow: 0px 2px 2px rgba(0,0,0,0.5);*/
    border-bottom: 1px solid #ddd;
    height: $headerHeight;

    .header-nav-option{
        color: $headerNavOptionColor;

        &.selected{
            color: $selectedHeaderNavOptionColor !important;

            &:not(.nav-option-icon){
                border-bottom: 4px solid $selectedHeaderNavOptionColor !important;
            }
        }

        &:hover{
            color: $selectedHeaderNavOptionColor;
        }
    }

    .nav-options > .header-nav-option.nav-option:hover{
        border-bottom:4px solid $light20SelectedHeaderNavOptionColor !important;
    }

    .wiki-nav-option {
        color: $headerNavOptionColor;
    }

    .wiki-title {
        font-weight: bold;
        text-align: center;
    }

    .wiki-button {
        width: 100px;
        padding: 8px;
    }
}

.app-logo {
    width: $leftPanelWidth;
    text-align: center;
    float: left;
    position: relative;

    img {
        float: left;
        padding: 5px 15px;
        height: 30px;
        max-width: $leftPanelWidth - 30px;
    }
}

.app-nav-container {
    padding: 0 15px;
    position: relative;

    nav > ul > li {
        position: static;
        float: left;
    }
}

.app-nav {
    float: left;
}

.nav-options{
    padding: 8px 0px;
}

.nav-option{
    @include user-select(none);
    cursor: pointer;
    margin: 3px 10px 0px 10px;
    padding: 0px 0px 5px 0px;
    color: $navOptionColor;
    list-style: none;
    height:20px;

    .nav-option-dropdown{
        position:absolute;
        z-index: 3;
        top:$headerHeight;
        border: 1px solid $hrMediumColor;
        background-color: white;
        border-radius: 0px 0px 5px 5px;
        display:none;
        color: $navOptionDropdownColor;
    }

    &.focused > .nav-option-dropdown{
        display:block;
    }
}

.nav-option > div.nav-option-title{
    float:right;
    margin-left:2px;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
}

.nav-options > .nav-option{
    border-bottom:4px solid transparent;
}

.nav-options > .nav-option:hover{
    border-bottom:4px solid $light40Color;;
}

.nav-options > .nav-option.focused{
    border-bottom:4px solid $light20Color;
}


.nav-options > .nav-option.selected{
    color: $highlightColor;

    &:not(.nav-option-icon){
        border-bottom:4px solid $highlightColor;
    }
}

.app-admin-controls {
    float: right;
}

.app-controls > .nav-option:hover{
    color: $selectedHeaderNavOptionColor;
}


.app-controls > .nav-option.selected{
    color: $selectedHeaderNavOptionColor;
    border-bottom:none;
}

.nav-lang{
    text-transform: uppercase;
    margin-top:8px;
    height:30px;

    &::after{
        font-family:'score';
        content: "\78";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 15px;
        top: 2px;
        position: relative;
        opacity:0.7;
    }

}

[popup-id=langSelectionPopup] {

    .sub-nav-options {
        margin:5px 0px;

        .nav-option {
            padding: 4px 8px;
        }
    }

}

nav > ul > li {
    position: relative;
    display: block;
}


.tabs{
    float:left;
    list-style: none;
    margin-top:10px;

    li{
        color: $light20Color;
        display:inline-block;
        padding-right:5px;
        padding-left:5px;
        cursor: pointer;
        border-bottom: 4px solid transparent;
        padding-bottom: 4px;
        white-space:nowrap;
        position:relative;
        margin:0px 2px;

        &:not(.add-tab):hover{
            border-bottom: 4px solid $light20Color;
        }

        .badge{
            float:left;
            position:relative;
            top:9px;
            width:12px;
            height:12px;
            margin-right:2px;
            background-color: $highlightColor;
            box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
            border-radius:50%;
            display:none;
        }


        &.empty{

            color: #ccc;

            &:not(.add-tab):hover{
                border-bottom: 4px solid #ccc;
            }

            .badge{
                display:none;
            }
        }

        &.selected{
            color: $highlightColor;
            cursor:default;
            border-bottom: 4px solid $highlightColor;

            &:not(.add-tab):hover{
                border-bottom: 4px solid $highlightColor;
            }
        }

        &.incomplete{

            color: $alertColor;

            &:not(.add-tab):hover{
                border-bottom: 4px solid $alertColor;
            }

            &.selected{
                border-bottom: 4px solid $alertColor;
            }

            .badge{
                display:none;
            }
        }


        .icon.tiny{
            position:relative;
            top:4px;

            &.icon-cross{
                opacity:0.5;
                &:hover{
                    opacity:1;
                }
            }

        }

    }
}


h3{
    .tabs{
        float:right;
        margin-top:-1px;

        li{
            padding-bottom: 10px;
            line-height:8px;
        }
    }
}

.toggle-tag{
    @include user-select(none);

    background-color: transparent;
    color: $mainColor;
    border: 2px solid $mainColor;
    padding: 2px 5px;
    border-radius: 0.3em;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    box-sizing: border-box;
    text-align: center !important;
    margin:5px 10px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    cursor:pointer;
    min-width: 100px;

    &.selected{
        background-color: $highlightColor;
        color: white;
        border: 2px solid $highlightColor;
        transition: all 0.2s ease-in;
    }
}

.view-tag{
    @include user-select(none);
    color: $mainColor;
    padding: 2px 5px;
    text-align: center !important;
    text-transform: uppercase;
    font-size: 12px;
    box-sizing: border-box;
    margin:5px 10px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;

    &.selected{
        color: $highlightColor;
        font-weight: bold;
    }
}

label.data {
    font-weight: bold;
}


.app-user-avatar{
    @include round-image(34px);
}

.app-controls > .nav-option > i.icon{
    font-size: 24px;
    line-height: 34px;
}



.user-popup-avatar{
    @include round-image(96px);
    float:left;
    margin:10px 0px 10px 10px;
}

.user-popup-data {
    float:left;
    border-left: 2px solid $light60MainColor;
    max-width:450px;
    padding: 5px 5px 5px 10px;
    margin: 10px;

    .user-popup-username {
        font-size:25px;
        font-weight: 300;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-top: -10px;
        margin-bottom:5px;
        padding-right:25px;
        border-bottom: 1px dashed transparent;

        &.selectable{
            cursor:pointer;
            padding: 2px 5px;

            &:hover{
                background-color: $light40Color;
                color: $highlightColor;
                border-radius: 0.2em;
                //border-bottom: 1px dashed $highlightColor;
            }

            &:after{
                font-family:'score';
                content:'x';
                opacity: 0.5;
                margin-top:6px;
                position: absolute;
                right: 25px;
            }
        }

    }

    .user-popup-description{
        color: #777;
        font-size:14px;
        font-style: italic;
        margin-bottom: 10px;
    }


    .user-popup-profile{
        float:left;
        position:relative;
        top:6px;
    }

}

.switch-user-row{
    cursor:pointer;

    &:hover{
        background-color: $light40Color;
    }
}

.switch-user-table-summary{
    padding: 5px 2px;

    color: $light20MainColor;

    &.alert{
        color: $alertColor;
    }
}


/** END HEADER **/


/** LEFT ASIDE **/
.page-left-panel {
    background: $lateralPanelsBackgroundColor;
    border-right: 1px solid $hrMediumColor;
    box-sizing:border-box;
    display:table;
    height:100%;
}

.left-aside-view{
    display:table-row;
    height:100%;
}

/** CONTEXT **/
.page-context{
    display:block;
    width:$leftPanelWidth;
    height: 150px;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    position: relative;

    div.context-description{
        @include text-overflow;
        text-transform: uppercase;
        margin-top:5px;
    }

    div.context-detail{
        @include text-overflow;
        text-transform: uppercase;
        font-size: 12px;
        font-style: italic;
        opacity:0.6;
    }

    n2s-multiavatar{
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border-width 0.3s ease-in-out;

        .inner-frame{
            transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border-width 0.3s ease-in-out;
        }

        .avatar-layout2 div{
            transition: all 0.3s ease-in-out;
        }

        .avatar-layout3 div{
            transition: all 0.3s ease-in-out;
        }

        .avatar-layout4 div{
            transition: all 0.3s ease-in-out;
        }
    }

}

.context-avatar{
    @include round-image(98px);

    div.icon-avatar{
        display: block;
        width: 100%;
        border: 0;
        margin: 0;
        background-color: $bigIconAvatarBackgroundColor;
        @include border-radius(50%);
        overflow:hidden;
        text-align:center;

        i.icon{
            color: white;
            font-size:65px;
            line-height: 86px;
            transition: font-size 0.3s ease-in-out,line-height 0.3s ease-in-out;

            &.icon-legalterms{
                margin-left:8px;
            }
        }
    }
}

.context-type-badge{
    color: white;
    position: absolute;
    top: 75px;
    left: 122px;
    background-color: $iconAvatarBackgroundColor;
    padding: 5px 3px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.5);
    width: 20px;
    height: 16px;
    transition: all 0.3s ease-in-out;

    [class^="context-icon-"], [class*=" context-icon-"] {
        font-family: 'score';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 0.8;
        font-size: 20px;
        background-image: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all 0.3s ease-in-out;
    }

    .context-icon-site:before{
        content: "\28";
    }

    .context-icon-sitewlan:before{
        content: "\28";
    }

    .context-icon-wlan:before{
        content: "\25";
    }

    .context-icon-group:before{
        content: "\e901";
    }

    .context-icon-realm:before{
        content: "\e901";
    }

    .context-icon-wlangroup:before{
        content: "\e902";
    }

    .context-icon-wlanname:before{
        content: "\e902";
    }

    .context-icon-multi:before{
        content: "\28";
    }
}

.app-container{

    #pageLoadingIndicator{
        & .vertical-wrapper{
            width:calc(100% - #{$leftPanelWidth});
            left:$leftPanelWidth;
        }
    }

    .left-menu-actions{
        display:table-row;

        section{
            display:table-cell;
            height: 74px;
            border-top: 1px solid #ccc;
            width: 100%;
            cursor:pointer;
        }

        .fold-menu-action{
            color:$mainColor;
            width: 30px;
            font-size: 30px;
            margin: -4px auto;
            transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
            &:hover{
                color: $highlightColor;
            }
        }
    }

    &.expanded{

        opacity: 1;
        transition: opacity .3s;
        transition-delay: .5s;

        .fold-menu-action{
            transform: rotateY(180deg);
        }

        .context-avatar{
            transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border-width 0.3s ease-in-out;
        }

        .context-description{
            transition: height 0.3s ease-in-out, opacity 0.5s ease-in-out;
        }

        .context-detail{
            transition: height 0.3s ease-in-out, opacity 0.5s ease-in-out;
        }
    }


    &.collapsed{

        opacity: 1;
        transition: opacity  .3s;
        transition-delay: .5s;

        #pageLoadingIndicator{
            & .vertical-wrapper{
                width:calc(100% - #{$collapsedLeftPanelWidth});
                left:$collapsedLeftPanelWidth;
            }
        }


        .page-header{
            transition: margin-left 0.3s ease-in-out;
        }

        .page-main-panel{
            left:$collapsedLeftPanelWidth;
            transition: left 0.3s ease-in-out;
        }

        .page-context{
            width:$collapsedLeftPanelWidth;
            height:70px;
            transition: width 0.3s ease-in-out, height 0.3s ease-in-out;


            .context-description{
                height:0px;
                opacity:0;
                margin:-10px;
            }

            .context-detail{
                height:0px;
                opacity:0;
            }

            .context-avatar{
                width:45px;
                height:45px;
                border-width:2px;
                transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border-width 0.3s ease-in-out;

                div.icon-avatar{
                    .icon{
                        font-size: 23px;
                        line-height: 35px;
                    }
                }
            }

            .context-edit-avatar{
                width:45px;
                height:45px;
                border-width:2px;

                div.icon-avatar{
                    .icon{
                        font-size: 23px;
                        line-height: 35px;
                    }
                }


                &:hover{
                    .context-edit-overlay{
                        opacity:1;
                        text-align: center;

                        &:after{
                            content:'\a1';
                            font-family:score;
                            font-size:20px;
                            color: white;
                            opacity:0.9;
                            top: 12px;
                            position:relative;
                        }
                    }
                }
            }

        }
    }
}


.context-popup-avatar{
    @include round-image(98px);
    float:left;
    margin:10px 0px 10px 10px;

    div.icon-avatar{
        display: block;
        width: 100%;
        border: 0;
        margin: 0;
        background-color:#DDD;
        @include border-radius(50%);
        overflow:hidden;
        text-align:center;

        i.icon{
            color: white;
            font-size:65px;
            line-height: 86px;
        }
    }

    span{
        display:block;
    }

    &.multi{
        width:75px;
        height:75px;
        display:inline-block;

        div.icon-avatar{
            i.icon{
                font-size:50px;
                line-height: 65px;
            }
        }
    }
}

.context-popup-table{
    font-size:14px;

    .details{
        opacity:0.5;
        font-size: 12px;
        margin:0px;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }

}


.context-popup-data {
    float: left;
    //border-left: 2px solid #eeeeee;
    //width: 360px;
    width: 480px;
    padding: 5px 0px 0px 10px;
    margin: 10px 10px 0px 10px;

    .context-popup-description {
        font-size:20px;
        font-weight: 300;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-top: -10px;
        margin-bottom:5px;

        &.selectable{
            cursor:pointer;
            padding: 2px 5px;

            &:hover{
                background-color: $light40Color;
                color: $highlightColor;
                border-radius: 0.2em;
            }

            &:after{
                font-family:'score';
                content:'x';
                float: right;
                opacity: 0.5;
                margin-top:6px;
            }
        }

    }

    .context-popup-detail{
        color: #777;
        font-size:14px;
        font-style: italic;
        margin-bottom: 10px;
    }

    &.multi{
        display:inline-block;
        padding-top:5px;
        border-left:none;
        width:270px;
    }

}

.context-popup-wrapper{
    width:520px;
    overflow:hidden;

    &.wlan-context{
        width:440px;

        .context-popup-data{
            width: 400px;
        }
    }
}

.switch-context-row{
    cursor:pointer;

    &:hover{
        background-color: rgba(0,0,0,0.05);
    }

    &.selected{
        color: $highlightColor;
        background-color: $tableSelectionBackground;
    }

}

.context-table-summary{
    height:20px;
    padding:5px 2px;
    color: $light20MainColor;
    border-top:2px solid $hrMediumColor;

    &.alert{
        color: $alertColor;
    }
}


.multiswitch-site-table{
    width:100%;
    height:250px !important;
    margin-bottom:20px;

    td div.details{
        width:290px;
    }
}

.multiswitch-wlan-table{
    width:100%;
    height:250px !important;
    margin-bottom:20px;

    td div.details{
        width:235px;
    }
}

.siteswitch-table{
    td div.details{
        width:410px;
    }
}

.wlanswitch-table{
    td div.details{
        width:350px;
    }
}


.context-table-headers{
    height:25px;
    border-bottom:2px solid #eee;
    line-height:25px;

    .context-title{
        letter-spacing: 0.5em;
        color: $tableHeaderColor;
        float:left;
        margin-left:20px;
    }

    .context-summary{
        color:$highlightColor;
        font-size:90%;
        float:right;
    }

}

.multiswitch-popup{
    width:480px;

    transition: width .2s;
    transition-timing-function: linear;

    .multiswitch-table-wrapper{
        display:table;
        width: 425px;
        margin-left:20px;
    }

    .multiswitch-wifiareas{
        width:400px;
        //float: left;
        display:table-cell;
        padding-right:7px;
    }

    .multiswitch-wlans{
        width:300px;
        display:table-cell;
        padding-left: 7px;
        border-left: 2px solid $light10HrColor;
    }

    &.with-wlans{
        width:790px;

        .multiswitch-table-wrapper{
            width:750px;
        }
    }


}


.sub-nav-options{

    margin-bottom:5px;

    a{
        text-decoration:none;
        outline:none;
    }

    .nav-option{
        padding:10px;
        clear: both;
        margin:0px;
        border-left:4px solid transparent;
        white-space: nowrap;
        overflow:hidden;
        text-overflow:ellipsis;

        i.icon{
            &.empty {
                padding-left: 20px;
            }
            //float:left;
            display:inline;
            margin-right:5px;
        }

        div.nav-option-title{
            display:inline;
            float:none;
            text-overflow: ellipsis;
            transition: all 0.3s ease-in-out;
        }
    }

    .nav-option:hover{
        border-left:4px solid $light20Color;
    }

    .nav-option.selected{
        color: $highlightColor;
        border-left:4px solid $highlightColor;
        background-color:rgba(0,0,0,0.1) !important;
    }

    hr.submenu-hr {
        border-top : 4px solid $highlightColor;
        margin: 0px 10px;
    }

    .submenu-hr .nav-option{
        background-color:rgba(0,0,0,0.05);
        margin-left: 5px;
    }

}

.org-selector {
    padding: 4px 8px 0px 8px;
    height: 20px;
    border-radius: 0.3em;
    margin-top: 4px;
    border: 1px solid ;

    &::after{
        font-family:'score';
        content: "\78";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 15px;
        top: 2px;
        position: relative;
        opacity:0.7;
        margin-left: 6px;
    }
}

.submenu-arrow {
    float: right;
    margin-top: 5px;
}

.submenu-arrow.selected {
    color: $highlightColor;
}

.page-sub-nav{
    display: table-cell;
    .sub-nav-options{
        margin-bottom:60px;
    }
}

#appContainer.collapsed{
    .page-sub-nav{
        .sub-nav-options{
            .nav-option{
                text-overflow:clip;
                i.icon{
                    margin-left: 5px;
                    font-size:24px;
                }
                .nav-option-title{
                    width:0;
                    transition: all 0.3s ease-in-out;
                    opacity:0;
                }
            }
        }
    }

    #pageContext{
        n2s-multiavatar{
            width:36px;
            height:36px;
            border:2px solid #ccc;
            padding:2px;

            .inner-frame{
                width:36px;
                height:36px;
                top:2px;
                left:2px;
                transition: all 0.3s ease-in-out;
            }

            .avatar-layout2 div {
                &:nth-child(1) {
                    width: 16px;
                }

                &:nth-child(2) {
                    left: 18px;
                    width: 16px;
                }
                transition: all 0.3s ease-in-out;
            }

            .avatar-layout3 div {
                &:nth-child(1) {
                    width: 16px;
                }

                &:nth-child(2) {
                    height: 16px;
                    left: 18px;
                    width: 16px;
                }

                &:nth-child(3) {
                    height: 16px;
                    left: 18px;
                    top: 18px;
                    width: 16px;
                }
                transition: all 0.3s ease-in-out;
            }

            .avatar-layout4 div {
                &:nth-child(1) {
                    height: 16px;
                    left: 0px;
                    width: 16px;
                }

                &:nth-child(2) {
                    height: 16px;
                    left: 0px;
                    top: 18px;
                    width: 16px;
                }

                &:nth-child(3) {
                    height: 16px;
                    left: 18px;
                    top: 0px;
                    width: 16px;
                }

                &:nth-child(4) {
                    height: 16px;
                    left: 18px;
                    top: 18px;
                    width: 16px;
                }
                transition: all 0.3s ease-in-out;
            }

            transition: all 0.3s ease-in-out;
        }

        .context-type-badge{
            top: 37px;
            left: 37px;
            width:10px;
            height:6px;
            transition: all 0.3s ease-in-out;

            .icon{
                font-size:10px;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.popup-nav-options{

    margin-bottom:5px;

    .nav-option{
        padding:10px;
        clear: both;
        margin:0px;
        border-left:4px solid transparent;
        white-space: nowrap;

        i.icon{
            float:left;
            margin-right:5px;
        }

        div.nav-option-title{
            float:left;
        }
    }

    .nav-option:hover{
        color: $highlightColor;
        border-left:4px solid $light20Color;
    }

    .nav-option.selected{
        border-left:4px solid $highlightColor;
    }
}


/** MAIN PAGE **/

.page-main-panel {
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    width: 100%;

    .page-main-panel-content {
        padding: 0px;
        height: 100%;
        box-sizing: border-box;
    }
}

.page-header {
    border-bottom: 1px solid $hrMediumColor;
    background: $pageHeaderBackground;
    height: $pageHeaderHeight;
    min-width: 824px;
    transition: margin-left 0.3s ease-in-out;
}

.page-header-title {
    padding: 12px 15px;

    h1{
        font-size: 30px;
        font-weight: normal;
        color: var(--main-clr);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    h2{
        font-size: 16px;
        font-weight: normal;
        color: #888;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

}

.page-main-panel-content {
    padding: $headerHeight 15px $footerHeight 15px;
    box-sizing: border-box;
}

.page-header-actions {
    padding: 12px;
    float: right;
}


/* FOOTER */
.page-footer-wrapper{
    overflow: hidden;
    height: calc(100vh - #{$headerHeight + $footerHeight});
}

.switch-page-view{
    height: 100%;
}

form {
    height: 100%;
}

.page-footer {
    width: 100%;
    height: $footerHeight;
    background: $footerBackground;
    border-top: 1px solid $hrMediumColor;
    box-sizing:border-box;
    padding: 10px;
    color: #666;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;

    &.with-inspector{
        margin-right: $rightPanelWidth;
    }

    &.with-inspector-and-tools{
        margin-right: $rightPanelWidth + $inspectorToolsWidth;
    }


}

.page-footer-logos{
    display:none;
    margin: 20px 0px
}

.page-footer-logo{
    margin: 0px 10px;
}
/******/

/** RIGHT ASIDE **/
.page-right-panel {

    position: fixed;
    margin-top: $headerHeight + $pageHeaderHeight + 1;
    top: 0;
    right: 0;
    bottom: 0;
    width: $rightPanelWidth;
    background-color: $lateralPanelsBackgroundColor;
    z-index: $lateralPanelsZIndex;
    display:none;
    box-sizing:border-box;
    border-left: 1px solid $hrMediumColor;
}

///
.inspector{

    /* display:table;*/
    /*  width: 100%;
      height: 100%;*/
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    width: $rightPanelWidth;
    top: 0px;
    bottom: 0;
    border-left: 1px solid $hrMediumColor;
}

.headless .inspector{
    top:0px;
}

.inspector-title{

    h3{
        background-color: #eee;
        border-bottom: 1px solid $hrMediumColor !important;
        height: 20px;
        line-height: 20px !important;
        padding: 10px !important;
        position: relative;
        color: #777;
    }

}

.inspector-tools{
    position:absolute;
    bottom:0px;
    top:0px;
    left:-($inspectorToolsWidth + 1);
    border-right: 1px solid $hrMediumColor !important;
    box-sizing:border-box;
    background-color: #eee;
    width:$inspectorToolsWidth;
    overflow-y:hidden;

    ul{
        list-style: none;

        li{

            font-size: 22px;
            padding: 5px 0px;
            width: 100%;
            text-align:center;
            color: #777;
            box-sizing: border-box;
            cursor:pointer;
            border-left: 4px solid transparent;
            float: left;
            overflow:hidden;

            &.selected{
                border-left: 4px solid $highlightColor;
                color: $highlightColor;
                cursor:default;
            }

            &.inspector-tool-tab:hover{
                border-left: 4px solid $light20Color;
            }

            &.inspector-tool-btn:hover{
                color: $highlightColor;
            }

        }
    }

}



.inspector-form{
    background-color: $lateralPanelsBackgroundColor;
    height: 100%;
}



///

.with-right-panel {
    .page-right-panel {
        display:block;
    }

    .page-main-panel{
        margin-right: $rightPanelWidth;
        min-width: $minBodyWidth - $leftPanelWidth - $rightPanelWidth;
    }

}


/** PAGE CONTENT **/

.page-content {

    display: flex;
    width: 100%;
    height: 100%;

    &.headless{

        #pageMainPanel {
            top: $headerHeight;
            height: calc(100vh - #{$headerHeight});

            header{
                display:none;
            }
        }

        .page-main-panel-content {
            padding-top: 0;
        }

        .page-right-panel{
            margin-top:40px;
        }

    }


    &.with-fixed-filters{
        #pageMainPanel{
            top: $headerHeight + $pageHeaderHeight + $fixedFilterHeight;
            height: calc(100vh - #{$headerHeight + $pageHeaderHeight + $fixedFilterHeight });
        }
    }
    &.with-fixed-filters-big{
        #pageMainPanel{
            top: $headerHeight + $pageHeaderHeight + $fixedFilterBigHeight;
            height: calc(100vh - #{$headerHeight + $pageHeaderHeight + $fixedFilterBigHeight });
        }
    }

}

.notification-description > ul{
    margin-left: 33px;
}

.notification-description > ol{
    margin-left: 33px;
}


/* BUTTONS */
button, .btn{
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color:$highlightColor;
    font-size:14px;
    border-radius: 0.3em;
    border:none;
    padding:4px 10px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width:100%;
    margin:5px;
    outline:0;
    color: white;
    cursor:pointer;
    display: inline-block;

    &.small{
        padding:3px 8px;
        font-size:12px;
    }

    &.tiny{
        padding:2px 4px;
        font-size:10px;
    }

    &:not([disabled]):hover{
        box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
    }

    &.alert{
        background-color: $alertColor;
    }

    &.round{
        @include border-radius(50%);
        padding: 18px 0px;
        opacity:.8;
        width: 52px;
        overflow:hidden;
        font-style:normal;
        font-weight:bold;
        text-align:center;
        margin:0px;

        &.small{
            font-size:14px;
            padding: 7px 2px;
            width:20px;
        }

        &.left-btn{
            border-radius: 30% 0 0 30%;
        }

        &.middle-btn{
            border-radius: 0%;
            margin-left:0px;
            margin-right:0px;
        }

        &.right-btn{
            border-radius: 0% 30% 30% 0%;
        }


        &:hover{
            opacity:1;
            box-shadow:none;
        }
    }
}

.icon-btn{
    display: inline-block;
    position: relative;
    background-color: $highlightColor;
    padding: 4px;
    border-radius: 2em;
    color: $pageHeaderBackground;
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    font-size: 25px !important;
    line-height: 1.8em !important;
    margin: 2px;
    vertical-align: top;
    opacity: .8;
    -webkit-transition: width .1s,background-color 1s,color 1s;
    -moz-transition: width .1s,background-color 1s,color 1s;
    transition: width .1s,background-color 1s,color 1s;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;

    &:hover{
        opacity:1;
    }

    &.undo{
        width:70px;
        &:after{
            content:",";
            margin-left:10px;
            font-size: 20px;
            position:relative;
            top:-1px;
            opacity:0.8;
        }
    }


    &.alert{
        background-color: $alertColor;
    }

    &.medium{
        padding: 7px;
        width: 26px;
        height: 26px;
        font-size: 21px !important;
        line-height: 25px !important;
        margin: 1px;
    }

    &.midsize{
        padding: 5px;
        width: 24px;
        height: 24px;
        font-size: 20px !important;
        line-height: 24px !important;
        margin: 1px;
    }

    &.small{
        padding: 4px;
        width: 18px;
        height: 18px;
        font-size: 16px !important;
        line-height: 18px !important;
        margin: 1px;
    }

    &.tiny{
        padding: 4px;
        width: 14px;
        height: 14px;
        font-size: 14px !important;
        line-height: 14px !important;
        margin: 1px;
    }

    &.table-size{
        padding: 4px;
        width: 16px;
        height: 16px;
        font-size: 16px !important;
        line-height: 16px !important;
        margin: 2px;
    }

    &.hollow{
        display:inline-block;
        background-color: transparent;
        padding:3px;
        border-radius:2em;
        color: $highlightColor;
        width: 40px;
        height: 40px;
        text-align:center;
        font-size: 25px;
        line-height: 42px !important;
        border: 3px solid $highlightColor;
        vertical-align:top;
        opacity:0.8;
        -webkit-transition: width 0.1s, background-color 1s, color 1s;
        -moz-transition: width 0.1s, background-color 1s, color 1s;
        transition: width 0.1s, background-color 1s, color 1s;
        -webkit-touch-callout: none;
        @include user-select(none);
        cursor:pointer;


        &.alert{
            color: $alertColor;
            border: 3px solid $alertColor;
        }

        &.medium{
            padding: 7px;
            width: 26px;
            height: 26px;
            font-size: 21px !important;
            line-height: 25px !important;
            margin: 1px;
        }

        &.small{
            padding: 3px;
            font-weight:bold;
            width: 18px;
            height: 18px;
            font-size: 14px !important;
            line-height: 18px !important;
            margin: 1px;
        }

        &.tiny{
            padding: 1px;
            font-weight: bold;
            width: 14px;
            height: 14px;
            font-size: 14px !important;
            line-height: 14px !important;
            margin: 1px;
        }

        &.table-size{
            padding: 3px;
            width: 14px;
            height: 14px;
            font-size: 14px !important;
            line-height: 14px !important;
            margin: 1px;
            font-weight: bold;
        }

    }

    &.waiting{
        cursor:wait;
        font-size:27px !important; /* hollow */
        line-height:46.5px !important;   /* hollow */

        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -ms-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -ms-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;

        -webkit-animation: spin-clockwise 1s linear infinite;
        -moz-animation: spin-clockwise 1s linear infinite;
        -o-animation: spin-clockwise 1s linear infinite;
        animation: spin-clockwise 1s linear infinite;
    }

    &.waiting:before{
        font-family:score;
        content:'i';
    }

    &.hollow.waiting{
        line-height:42.5px !important;   /* hollow */
    }
}


.icon-radio{
    display: inline-block;
    position: relative;
    background-color: transparent;
    padding: 4px;
    border-radius: .5em;
    color: #999;
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    font-size: 25px !important;
    line-height: 1.8em !important;
    margin: 2px;
    vertical-align: top;
    opacity: .8;
    -webkit-transition: width .1s,background-color 1s,color 1s;
    -moz-transition: width .1s,background-color 1s,color 1s;
    transition: width .1s,background-color 1s,color 1s;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;

    &:hover{
        opacity:1;
        background-color: #eee;
        color:#777;
    }


    &.medium{
        padding: 2px 7px;
        height: 24px;
        font-size: 20px !important;
        line-height: 24px !important;
        margin: 0px 1px;
        border-radius: 5px;
    }

    &.small{
        padding: 4px;
        width: 18px;
        height: 18px;
        font-size: 16px !important;
        line-height: 18px !important;
        margin: 1px;
    }

    &.tiny{
        padding: 4px;
        width: 14px;
        height: 14px;
        font-size: 14px !important;
        line-height: 14px !important;
        margin: 1px;
    }

    &.selected{
        opacity:1;
        background-color: #C9DEE9;
        color: $highlightColor;
    }
}



.actions-btn{
    display: inline-block;
    position: relative;
    background-color: $highlightColor;
    padding: 2px;
    border-radius: 50%;
    color: $pageHeaderBackground;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 25px !important;
    line-height: 5px !important;
    margin: 2px;
    vertical-align: top;
    opacity: .3;
    font-style: normal;

    -webkit-transition: width .1s,background-color 1s,color 1s;
    -moz-transition: width .1s,background-color 1s,color 1s;
    transition: width .1s,background-color 1s,color 1s;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;

    &:hover{
        opacity:1;
    }

    &:before{
        content:'...';
    }

    &.waiting{
        cursor:wait;
        font-size:18px !important;
        padding:2px;
        line-height:20px !important;

        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -ms-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -ms-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;

        -webkit-animation: spin-clockwise 1s linear infinite;
        -moz-animation: spin-clockwise 1s linear infinite;
        -o-animation: spin-clockwise 1s linear infinite;
        animation: spin-clockwise 1s linear infinite;
    }

    &.waiting:before{
        font-family:score;
        content:'i';
    }

    &.small {
        width: 17px;
        height: 17px;
        font-size: 17px!important;
        line-height: 8px!important;
        margin: 1px;
        padding: 2px;
    }
}


.clone-btn{
    display: inline-block;
    position: relative;
    background-color: $highlightColor;
    padding: 2px;
    border-radius: 50%;
    color: $pageHeaderBackground;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 15px !important;
    //line-height: 17px !important;
    margin: 2px;
    vertical-align: top;
    opacity: .3;
    font-style: normal;

    -webkit-transition: width .1s,background-color 1s,color 1s;
    -moz-transition: width .1s,background-color 1s,color 1s;
    transition: width .1s,background-color 1s,color 1s;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;

    &:hover{
        opacity:1;
    }

    &:before{
        font-family:'score';
        content: "\e912";
    }

    &.waiting{
        cursor:wait;
        font-size:18px !important;
        padding:2px;
        line-height:20px !important;

        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -ms-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -ms-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;

        -webkit-animation: spin-clockwise 1s linear infinite;
        -moz-animation: spin-clockwise 1s linear infinite;
        -o-animation: spin-clockwise 1s linear infinite;
        animation: spin-clockwise 1s linear infinite;
    }

    &.waiting:before{
        font-family:score;
        content:'i';
    }
}


a.btn{
    text-decoration:none !important;
}

/////////
.badge{
    background-color: $highlightColor;
    padding: 2px 5px;
    border-radius: 0.3em;
    color: white;
    text-align: center;
    text-transform:uppercase;
    font-size:12px;
    box-sizing:border-box;
    text-align: center !important;

    &.alert{
        background-color: $alertColor;
    }

    &.hollow{
        background-color: transparent;
        color: $highlightColor;
        border: 2px solid $highlightColor;

        &.alert{
            color: $alertColor;
            border: 2px solid $alertColor;
        }
    }
}

.icon-badge{
    display: inline-block;
    position: relative;
    background-color: $highlightColor;
    border-radius: 2em;
    color: $pageHeaderBackground;
    text-align: center;

    padding: 7px;
    width: 26px;
    height: 26px;
    font-size: 21px !important;
    line-height: 25px !important;
    margin: 1px;

    vertical-align: top;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;

    &.overlayed{
        background-color: white;
        color: black;
    }

    &.small{
        padding: 7px;
        width: 16px;
        height: 16px;
        font-size: 16px !important;
        line-height: 16px !important;
        margin: 1px;
    }

    &.hollow{
        display:inline-block;
        background-color: transparent;
        border-radius:2em;
        color: $highlightColor;
        text-align:center;
        border: 3px solid $highlightColor;

        padding: 6px;
        width: 24px;
        height: 24px;
        font-size: 20px !important;
        line-height: 25px !important;
        margin: 1px;

        vertical-align:top;
        -webkit-touch-callout: none;
        @include user-select(none);
        cursor:pointer;

        &.overlayed{
            color: white;
            border-color: white;
        }

        &.small{
            padding: 5px;
            font-weight:bold;
            width: 14px;
            height: 14px;
            font-size: 14px !important;
            line-height: 14px !important;
            margin: 1px;
        }
    }
}



@import 'table.scss';
@import 'form.scss';
@import 'overlay.scss';
@import 'modal.scss';
@import 'wizard_score.scss';
@import 'splash_editor.scss';



/* GRID */
div.grid12{
    clear:both;
    box-sizing:border-box;
    padding:5px;
    width: 100%;
}

div.grid11{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 11 / 12);
}

div.grid10{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 10 / 12);
}

div.grid9{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 9 / 12);
}

div.grid8{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 8 / 12);
}

div.grid7{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 7 / 12);
}

div.grid6{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 6 / 12);
}


div.grid5{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 5 / 12);
}

div.grid4{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 4 / 12);
}

div.grid3{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 3 / 12);
}

div.grid2{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 2 / 12);
}

div.grid1{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 1 / 12);
}


div.grid05{
    float:left;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 1 / 24);
}

/* END GRID */

/* INLINE-GRID */
div.igrid12{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: 100%;
}

div.igrid11{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 11 / 12 - 0.3em);
}

div.igrid10{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 10 / 12 - 0.3em);
}

div.igrid9{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 9 / 12 - 0.3em);
}

div.igrid8{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 8 / 12 - 0.3em);
}

div.igrid7{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 7 / 12 - 0.3em);
}

div.igrid6{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 6 / 12 - 0.3em);
}


div.igrid5{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 5 / 12 - 0.3em);
}

div.igrid4{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 4 / 12 - 0.3em);
}

div.igrid3{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 3 / 12 - 0.3em);
}

div.igrid2{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 2 / 12 - 0.3em);
}

div.igrid1{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 1 / 12 - 0.3em);
}


div.igrid05{
    display: inline-block;
    vertical-align:top;
    box-sizing:border-box;
    padding:5px;
    width: calc(100% * 1 / 24);
}

/* END IGRID */


/* USER EDIT */
.user-edit-avatar{
    @include round-image(80px);
    cursor: pointer;
    border-width:2px;
    position:relative;

    .user-edit-overlay{
        position:absolute;

        top:0px;
        left:0px;

        border: 3px solid white;
        box-sizing: border-box;
        width:100%;
        height:100%;

        background-color:rgba(0,0,0,0.4);
        border-radius:50%;
        opacity:0;
        transition: all .3s ease-in-out;
        text-align: center;
    }

    & > img[src='/cms/']{
        display:none;
    }

    &.unset{

        border-color: $alertColor;

        .user-edit-overlay{
            opacity:0.5;
            background-color:$alertColor;

            &:after{
                content: "\2b";
                font-family:score;
                font-size:33px;
                color: white;
                opacity:0.9;
                top: 18px;
                position:relative;
            }
        }
    }

    &.popup-size{
        width: 96px;
        height: 96px;
        border-width: 3px;

        .user-edit-overlay{
            &:after{
                top:26px !important;
            }
        }
    }

}

.user-edit-avatar:hover{
    //border-color: #8FB9E3;

    .user-edit-overlay{
        opacity:1;

        &:after{
            content: "\a1";
            font-family:score;
            font-size:33px;
            color: white;
            opacity:0.9;
            top: 20px;
            position:relative;
        }
    }
}



.context-edit-avatar{
    @include round-image(96px);
    cursor: pointer;
    border-width:3px;
    position:relative;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border-width 0.3s ease-in-out;

    .context-edit-overlay{
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.4);
        border-radius:50%;
        opacity:0;
        transition: all .3s ease-in-out;
    }

    & > img[src='/cms/']{
        display:none;
    }

    &:hover{
        .context-edit-overlay{
            opacity:1;
            text-align: center;

            &:after{
                content:'\a1';
                font-family:score;
                font-size:40px;
                color: white;
                opacity:0.9;
                top: 24px;
                position:relative;
            }
        }
    }
}

/*
.context-edit-avatar:hover{
  //border-color: #8FB9E3;

  .context-edit-overlay{
    opacity:1;
    text-align: center;

    &:after{
      content:'W';
      font-family:score;
      font-size:40px;
      color: white;
      opacity:0.9;
      top: 24px;
      position:relative;
    }
  }

}
*/


.table-cell-avatar{
    @include round-image(40px);
    margin:0px 10px;
    float: left;

    i.icon{
        font-size:20px;
        line-height:20px;

        color: white !important;
        padding: 6px;
        background-color: $iconAvatarBackgroundColor !important;
        @include border-radius(50%);
        top: 2px;
        position: relative;
        left: 2px;
        width:20px;
        height:20px;
        display:inline-block;
    }

    img{
        box-sizing:border-box;
        border:1px solid white;
        width:100%;
        height:100%;
    }
}

.table-cell-avatar-emailerror{
    color: var(--alert-clr);
    font-style: italic;
}

.table-cell-user{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    line-height: 40px;
    float:left;
    max-width:175px;
}


.activity-PUT{
    background-color: $alertColor !important;
}

.activity-GET{
    background-color: #008000 !important;
}

.activity-POST{
    background-color: $alertColor !important;
}

.activity-DELETE{
    background-color: #C71C07 !important;
}

.badge.PROFILE_LOBBY{
}

.badge.PROFILE_WIFIAREA_ADMIN{
}

.badge.PROFILE_MARKETING{
}

.badge.PROFILE_IT{
}

.badge.PROFILE_N2S_LEVEL1{
    color: #60307A;
    border-color: #60307A;
}

.badge.PROFILE_N2S_LEVEL2{
    color: #BC5914;
    border-color: #BC5914;
}

.badge.PROFILE_N2S_LEVEL3{
    color: #C71C07;
    border-color: #C71C07;
}

.badge.PROFILE_TENANT_LEVEL1{
    color: #60307A;
    border-color: #60307A;
}

.badge.PROFILE_TENANT_LEVEL2{
    color: #BC5914;
    border-color: #BC5914;
}

.badge.PROFILE_SUPERUSER{
    color: #C71C07;
    border-color: #C71C07;
}

.calendar-selector{
    background-color:$light40Color;
    border-radius: 0.5em;
    padding:5px 10px 10px 10px;
    display:inline-block;
}

.calendar-selector div {
    text-align: center;
    margin-bottom:5px;
    color: $highlightColor;
    font-weight:bold;
}

.calendar-veil{
    position:absolute;
    top:30px;
    left:3px;
    width:202px;
    height:190px;
    background-color:rgba(255, 255, 255, 0.9);
    color: $highlightColor;
    font-size:18px;
    border-radius:5px;
}

div.notification-exception{
    height:100%;
    position:relative;
    width:100%;
    text-align:center;

    h1{
        font-size:20px;
        text-align:center;
        color: $highlightColor;
        font-weight:300;
        position:relative;
        top:10px;
        width:100%;
    }

    div[class^="icon-"], div[class*=" icon-"]{
        margin:10px auto 0px;
        font-size:60px;
        line-height:70px;
        width:80px;
        height:80px;
        border-radius:50%;
        background-color: $highlightColor;
        padding:10px;
        opacity:0.3;
        color:white;
    }

}

/*END USER EDIT */


/* GENERIC CLASSES */
.fullwidth{
    width:100%;
}


/* 
    Components & Angular directives.
    This whole block should be placed at the end of this file, to mantain style hierarchies
*/
@import '../js/Angular/Directives/Popup/Popup.scss';
@import '../js/Angular/Directives/Image/Image.scss';
@import '../js/Angular/Directives/InlineInput/InlineInput.scss';
@import '../js/Angular/Directives/ItemSelector/ItemSelector.scss';
@import '../js/Angular/Directives/Pagination/Pagination.scss';
@import '../js/Angular/Directives/ProgressButton/ProgressButton.scss';
@import '../js/Angular/Directives/Searchbox/Searchbox.scss';
@import '../js/Angular/Directives/Select/Select.scss';
@import '../js/Angular/Directives/TableFilter/TableFilter.scss';
@import '../js/Angular/Directives/Tagbag/Tagbag.scss';
@import '../js/Angular/Directives/Multiselect/Multiselect.scss';
@import '../js/Angular/Directives/Timerange/Timerange.scss';
@import '../js/Angular/Directives/ToggleButton/ToggleButton.scss';
@import '../js/Angular/Directives/Weekdaycontrol/Weekdaycontrol.scss';
@import '../js/Angular/Directives/Radiocontrol/Radiocontrol.scss';
@import '../js/Angular/Directives/Autocombo/Autocombo.scss';
@import '../js/Angular/Directives/Calendar/Calendar.scss';
@import '../js/Angular/Directives/Datepicker/Datepicker.scss';
@import '../js/Angular/Directives/Spinner/Spinner.scss';
@import '~textangular/src/textAngular.css';
@import '../js/Angular/Directives/ColorPicker/ColorPicker.scss';
@import '../js/Angular/Directives/FontPicker/FontPicker.scss';
@import '../js/Angular/Directives/UnitBasedInput/UnitBasedInput.scss';
@import '../js/Angular/Directives/Slider/Slider.scss';
@import '../js/Angular/Directives/Multiavatar/Multiavatar.scss';
@import '../js/Angular/Directives/Bigselect/Bigselect.scss';
@import '../js/Angular/Directives/Tooltip/Tooltip.scss';
@import '../js/Angular/Directives/Map/MultimarkerMap.scss';
@import '../js/Angular/Directives/GroupSelector/GroupSelector.scss';