@charset "UTF-8";

.page-wizard-steps{
  display:table-cell;
}

.wizard-step-wrapper{
  position:relative;
  
  &.readonly{
    overflow:hidden;  
  }
}

.wizard-inspector{

  margin-bottom:40px;

  .wizard-inspector-step{

    @include user-select(none);
    padding:10px;
    clear: both;
    margin:0px;
    border-left:4px solid transparent;

    cursor: pointer;
    //margin: 3px 0px 0px 0px;
    padding: 4px 4px 4px 0px;
    color: $navOptionColor;
    list-style: none;
    height:30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    i.icon{
      float:left;
      margin-right:5px;
    }

    .wizard-inspector-index{
      display:inline-block;
      /*border-radius:50%;*/
      padding: 8px 6px;
      color: $light20NavOptionColor;
      margin-right:5px;
      font-family: score;
      font-size:20px;
      position: relative;
      top: -3px;
    }

    .wizard-inspector-title{
      display:inline;
      color: $navOptionColor;
      text-transform:uppercase;
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow-x:hidden;
      position:relative;
      top:-12px;
    }

    .wizard-inspector-detail{
      font-size: 80%;
      margin-left: 38px;
      position: relative;
      color: $light20NavOptionColor;
      white-space:nowrap;
      text-overflow:ellipsis;
      position:relative;
      top:-14px;

/*
      &:before{
        font-family:score;
        content:'1';
      }
*/
    }


    &:hover{
      border-left:4px solid $light20Color;
    }

    &.selected{
      border-left:4px solid $highlightColor;
      color: $highlightColor;
      background-color: rgba(0,0,0,0.05);

      .wizard-inspector-index{
        color: $light20Color;
      }

      .wizard-inspector-title{
        color: $highlightColor;
      }

      .wizard-inspector-detail{
        color: $light20Color;
      }
    }


    &.alert{

      color: $alertColor;

      &:hover{
        border-left-color:$lightAlertColor;
      }

      &.selected{
        border-left-color:$alertColor;
      }

      .wizard-inspector-index{
        color: $lightAlertColor;
      }

      .wizard-inspector-title{
        color: $alertColor;
      }

      .wizard-inspector-detail{
        color: $lightAlertColor;
      }
    }

    &[disabled]{
      opacity:0.5;
    }


    &.wizard-inspector-substep{

      height:20px;

      .wizard-inspector-index{
        display:none;
      }

      .wizard-inspector-title{
        display:none;
      }

      .wizard-inspector-detail{
        line-height:45px;
      }

    }

  }

}

.wizard2-inspector{
  margin:0px;
  padding: 20px 10px 5px 35px;
  display: flex;

  .wizard2-inspector-step{

    @include user-select(none);
    clear: both;
    float: left;
    margin:0px;
    cursor: default;
    color: $navOptionColor;
    list-style: none;
    height:38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    margin-right: 10px;

    .wizard2-inspector-info{
      float: left;
      background-color: $light30Color;
      padding: 4px 4px 4px 0px;
      min-width: 270px;
      width: calc(100% - 30px);
      height: 30px;
    }

    i.icon{
      float:left;
      margin-right:5px;
    }

    .wizard2-inspector-index{
      display: inline-block;
      color: $navOptionColor;
      font-size: 20px;
      width: 25px;
      margin-left: 5px;
    }

    .wizard2-inspector-icon{
      display:inline-block;
      padding: 8px 0px;
      color: $light20NavOptionColor;
      margin-right:5px;
      font-family: score;
      font-size:20px;
      position: relative;
    }

    .wizard2-inspector-title{
      display:inline;
      color: $navOptionColor;
      text-transform:uppercase;
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow-x:hidden;
      position:relative;
      top:-12px;
    }

    .wizard2-inspector-detail{
      font-size: 80%;
      margin-left: 60px;
      position: relative;
      color: $light20NavOptionColor;
      white-space:nowrap;
      text-overflow:ellipsis;
      top:-14px;
    }

    .wizard2-inspector-arrow{
      float: left;
      border-left: 20px solid $light30Color;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }

    &.selected{
      color: white;

      .wizard2-inspector-info{
        background-color: $highlightColor;
      }

      .wizard2-inspector-index{
        color: white;
      }

      .wizard2-inspector-icon{
        color: white;
      }

      .wizard2-inspector-title{
        color: white;
      }

      .wizard2-inspector-detail{
        color: white;
      }

      .wizard2-inspector-arrow{
        border-left: 20px solid $highlightColor;
      }
    }

    &.next{

      .wizard2-inspector-info{
        background-color: rgba(0,0,0,0.05);
      }

      .wizard2-inspector-arrow{
        border-left: 20px solid rgba(0,0,0,0.05);
      }
    }


    &.alert{

      &.selected{
        .wizard2-inspector-info{
          background-color: $alertColor;
        }

        .wizard2-inspector-arrow{
          border-left: 20px solid $alertColor;
        }
      }

      &.next{
        .wizard2-inspector-info{
          background-color: $lightAlertColor;
        }

        .wizard2-inspector-arrow{
          border-left: 20px solid $lightAlertColor;
        }
      }
    }

    &[disabled]{
      opacity:0.5;
    }

  }

}



.app-container{
  &.expanded{
  }

  &.collapsed{
    .wizard-inspector-title{
      width:0;
      transition: all 0.3s ease-in-out;
      display:none;
    }

    .wizard-inspector-detail{
      width:0;
      transition: all 0.3s ease-in-out;
      display:none;
    }
    .wizard-inspector-index{
      margin-left: 8px;
      font-size:24px;
    }

  }
}
