@import "globals.scss";

html, button, input{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 1em;
  background: transparent;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  background: $highlightColor;
  border: 2px solid $light45Color;
  border-radius: 50%;
  cursor: pointer;
  top: 25%;
  transform: translateY(-25%);
}

input[type="range"]::-webkit-slider-runnable-track {
  background: $light45Color;
  height: 0.5em;
  border-radius: 0.25em;
}

.add-item-btn{
  display:block;
  border:2px dashed $light20Color; 
  border-radius:5px;
  text-align:center;
  line-height:22px;
  color:$highlightColor;
  font-size:12px;
  margin:auto;
  cursor:pointer;
  text-decoration: none;
  text-transform: uppercase;

  &.double-size{
    line-height: 40px;
    height:40px;
  }
}

.drag-and-drop-zone{
  display:block;
  border:2px dashed $light20Color;
  border-radius:5px;
  text-align:center;
  line-height:50px;
  color:$highlightColor;
  font-size:16px;
  margin:auto;
  text-decoration: none;

  &.drag-and-drop-zoom{
    border:3px dashed $light10Color;
    line-height: 60px;
  }
}

.drag-and-drop-zone input{
  display:none;
}

.drag-and-drop-zone .browse-link, .browse-link-button{
  cursor: pointer;
  background-color: $highlightColor;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-size:16px;
  box-sizing:border-box;
  text-align: center !important;

  &:not([disabled]):hover{
    box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  }
}

.floor-gallery{
  height: 160px;
  overflow-x: auto;

  &.floor-gallery-text{
    text-align:center;
    color:$highlightColor;
    font-size:16px;
  }
}

.gallery-pic{
  position: relative;
  height: 120px;
  padding: 15px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;
}

.floor-gallery-image{
  border:2px solid transparent;
  height: 120px;
  min-height: 120px;
  min-width: 70px;
  max-width: 300px;
  object-fit: contain;

  &.selected{
    border:2px solid $light20Color;
  }
  &.error{
    border:2px solid $warningStatusColor;
  }
}

.floor-gallery-arrow{
  position: absolute;
  font-family: score;
  cursor: pointer;
  font-size: 30px;
  top: calc(50% - 15px);
  color: white;
  background-color: $highlightColor;
  opacity: 55%;
  border-radius: 50%;

  &.left{
    left: 15px;
    &:before {
      content: "\77";
    }
  }

  &.right{
    right: 15px;
    &:before {
      content: "\7a";
    }
  }
  &:hover{
    opacity: 90%;
    font-size: 36px;
    top: calc(50% - 18px);
  }
}

.floor-gallery-close{
  position: absolute;
  top: 0px;
  right: 0px;
  font-family: score;
  font-size: 20px;
  cursor: pointer;
  color: $highlightColor;
  opacity: 55%;

  &:before {
    content: "\66";
  }
  &:hover{
    opacity: 90%;
  }
}

#heatmapContainerWrapper { width:100%; height:100%; position:absolute; }
#heatmapContainer { width:100%; height:100%;}
heatmap { width:100%; height:100%; position:absolute;}
.heatmapLegend {
  background:rgba(255,255,255,0.5);
  position:absolute;
  padding:5px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}

#heatmapLegendMin {
  float: left;
  margin-right: 10px;
}

#heatmapLegendMax {
  float: right;
  margin-left: 10px;
}

.floor-point {
  position: absolute;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}
.floor-point-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}
.floor-point-tag{
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
.floor-point-tag-text{
  background-color: rgba(64,64,64,0.4);
  color: white;
  border-radius: 8px;
  padding: 4px;
}
.floor-circle {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.floor-point:hover .tooltip {
  opacity: 1;
}

.floors-time {
  font-size: 70%;
  color: $highlightColor;
  background-color: var(--control-bkg);
  padding: 1px 10px;
  border-radius: 50px;
  margin: 5px;
}

/* LOADING INDICATOR */
#pageLoadingIndicator{
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,0.7);
  z-index:99;

  & .vertical-wrapper{
    position:absolute;
    top:50%;
    height:1px;
    text-align:center;
    /*
    width:calc(100% - #{$leftPanelWidth});
    left:$leftPanelWidth;
    */
    transition: width 0.3s ease-in-out, left 0.5s ease-in-out;


    svg{
      stroke:$highlightColor;
      position:relative;
      top:-40px;
    }

  }


  &.panelless{
    & .vertical-wrapper{
      width:100%;
      left:0px;
    }
  }
}

/* EXCEPTION FRAMES */
.exception-frame{
  position:relative;  
  margin-left:0px;
  margin-right:0px;
  width:100%;
  overflow:hidden;
  min-height:200px;
  background-color: $light40Color;
  border-radius: 0.5em;
  margin-top:10px;

  .exception-frame-content{
    z-index:2;
    position:absolute;
    left:50px;
    bottom:50px;
    width:50%;
    min-width:600px;

    h1{
      font-size:40px;
      font-weight:300;
      color:$mainColor;
    }

    h2{
      font-size:30px;
      font-weight:300;
      color:$mainColor;
    }

    p{     
      text-align:justify;
      color: $mainColor;
      margin: 15px 5%;
    }

    hr{
      height: 2px;
      border: none;
      background-color: $trans20HrColor;
    }

    .right{
      position:absolute;
      right:0px;
    }

    .exception-frame-exception{       
      font-size:13px;
      margin:15px 5%;
      width:90%;
      border-top: 1px dotted $hrMediumColor;

      th{
        text-align:left;
        color: $mainColor;
        font-weight: bold;        
      }

      td{
        text-align:left;
        color: $mainColor;
      }
    }    
  }

  .exception-frame-icon, .exception-frame-httperror{
    position:absolute;
    bottom:0px;
    right:10px;


    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: $light20Color;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
    
    font-size: 176px;
    line-height: 200px;
  }

  .exception-frame-icon{
    font-family: 'score';
  }
  
  &.exception-view {
    min-height:500px;
    bottom:50px;
    margin-top:0px;
    right:10px;
    background-color: transparent;
    

    .exception-frame-icon{
      font-size:330px;
      line-height:330px;
      color:rgba(0,0,0,0.1);
    }

    .exception-frame-httperror{
      font-size:330px;
      line-height:330px;
      font-weight:700;
      color:rgba(0,0,0,0.1);
    }
  }


  &.thin{
    min-height:160px;

    .exception-frame-content{

      h1{
        font-size:25px;
      }

      h2{
        font-size:20px;
      }
    }

    .exception-frame-icon{
      font-size: 140px;
      line-height: 160px;
    }

  }


  &.as-button{
    cursor:pointer;
  }

}


/* EXCEPTION FRAMES */
.exception-full-frame{
  width:100%;
  text-align:center;
  margin-top:50px;

  .exception-frame-content{
    max-width: 500px;
    margin: auto;

    h2{
      font-size:20px;
      font-weight:300;
      color:$mainColor;
      text-align:center;
    }

    p{     
      text-align:justify;
      color: $mainColor;
      margin: 15px 5%;
    }

  }

  .exception-frame-icon{
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: $light40Color;
    font-family: 'score';
    line-height:200px;
    font-size:135px;
    margin-bottom:20px;
    text-shadow: 0px 2px 2px $light20Color;
    text-align:center;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
  }

  .add-item-btn{
    margin-top:20px;
    max-width: 500px;
  }

  &.thin{
    margin-top:0px;
    .exception-frame-icon{
      margin-bottom:0px;
    }
  }

  &.thinnest{
    margin-top:0px;
    .exception-frame-icon{
      margin-bottom:0px;
      line-height: unset;
    }
  }

}


.big-count{
  display: inline-block;
  margin:0px 10px 0px;
  min-width:120px;
  text-align:center;

  .number{
      font-size: 32px;
      font-weight: 100;        
      color: $highlightColor;
  }

  h4 {
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
  }
}

.disabled {
  opacity: 0.5!important;
  cursor: not-allowed !important;
}