n2s-group-selector{

    &.disabled{
        opacity:0.5;
        cursor: not-allowed;
    }

    &.ng-invalid{

        .n2s-groupselector-selected{
            &:after{
                opacity: 1;
                color: $alertColor;
                top: 12px;
            }

            color:$alertColor;
            background-color:$inputAlertBackground;
            border-radius: 5px;

            .n2s-groupselector-selected-badge{
                opacity:0.8;
                color: $alertColor;
                border-color: $alertColor;
            }
        }
    }

    &.small{
        .n2s-groupselector-selected{
            height: unset;

            &:after{
                top: 7px;
            }

            .n2s-groupselector-selected-badge{
                display:none;
            }
        }

        .n2s-groupselector-selected-name{
            width: 100%;
            line-height: unset;
        }
    }
}

.n2s-groupselector-search{
    width:calc(100% - 165px);

    &.only2{
        width:calc(100% - 110px)
    }

    &.only1{
        width:calc(100% - 55px)
    }
}

.no-sites{
    .n2s-groupselector-search{
        width:calc(100% - 110px);

        &.only1{
            width:calc(100% - 55px)
        }
    }

    .filter-sites{
        display:none;
    }
}

.only-organizations{
    .n2s-groupselector-search{
        width:100%;
    }

    .icon-radio{
        display:none;
    }

}

.only-sites{
    .n2s-groupselector-search{
        width:100%;
    }

    .icon-radio{
        display:none;
    }
}

.n2s-groupselector-selected{
    font-size:1em;
    color:$mainColor;
    cursor:pointer;
    text-align: left;
    position:relative;
    padding:5px 20px 5px 5px;
    box-sizing:border-box;
    white-space: nowrap;
    overflow:hidden;
    height:38px;

    &:after{
        font-family: 'score';
        content:'\78';
        top: 12px;
        right:5px;
        opacity:0.5;
        margin-left:10px;
        position:absolute;
    }

    &:hover{
        &:after{
            opacity: 1;
            color: $highlightColor;
        }
        color:$highlightColor;
        background-color: $light40Color;
        border-radius: 5px;
        //border-bottom: 1px dashed $highlightColor;
    }

    .n2s-groupselector-selected-name{
        display: inline-block;
        width: calc(100% - 70px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height:14px;
    }

    .n2s-groupselector-selected-badge{
        display: inline-block;
        width: 50px;
        margin-left: 10px;
        padding:4px;
    }

}

.n2s-groupselector-table{

    width:100%;
    border-color: #ddd;
    border-collapse: collapse;

    .details{
        opacity:0.5;
        font-size: 12px;
        margin:0px;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }

    tr{
        td{
            .badge{
                padding:4px;
            }
            padding:4px;
            cursor:pointer;
            border-bottom: 1px solid #eee;
            color: $mainColor;
        }


        &:hover{
            td{
                background-color: $light40Color;
                color: $highlightColor;
            }
        }

    }
}

.n2s-groupselector-search-container {
    width:100%;
    padding-bottom:10px;
    border-bottom:2px solid #eee;
    margin-bottom:2px;
}

.overlay {

    n2s-group-selector{

        line-height:25px;

        .n2s-groupselector-selected{

            color: #aaa;

            &:hover{
                color: white;
                background-color:transparent;

                &:after{
                    opacity: 1;
                    color: white;
                }

            }

            .n2s-groupselector-selected-name{
                line-height: unset;
            }

            .n2s-groupselector-selected-badge{
                color:#999;
                border-color:#999;
            }

            &:after{
                color: #999;
                top:10px;
            }
        }

        &.ng-invalid{
            .n2s-groupselector-selected{
                background-color: transparent;
            }
        }
    }
}

.overlay-popup{

    .icon-radio{
        &.selected{
            background-color:unset;
        }

        &:hover{
            background-color:unset;
            color: white;
        }

    }
    .n2s-groupselector-search-container {
        border-bottom:2px solid var(--main-clr);

        .n2s-searchbox-container{

            .icon{
                color: #999;
            }

            .clear{
                color: #999;
            }

            input{
                background-color: rgba(0,0,0,0.1);
                border:none!important;
                color: #999 !important;

                &:focus{
                    background-color: rgba(0,0,0,0.1) !important;
                    color:white !important;
                }

                &:hover{
                    background-color: rgba(0,0,0,0.1) !important;
                    color: #999 !important;
                }
            }
        }

    }

    .n2s-groupselector-table{
        margin-top:-4px;

        td {
            color: #999;
            border-color: var(--main-clr);

            .badge{
                &.hollow{
                    border-color: #999;
                    color: #999;
                }
            }

        }

        tr:hover{
            td{
                background-color: rgba(0,0,0,0.1);
                color:white;
            }

        }
    }

}