.splash-template-gallery{
    margin:5px;
    box-sizing: border-box;
    max-width: 288px;
    min-width: 144px;
}

.splash-template-thumbnail{ 
    text-align:center;
    float:left;
    margin:5px;
    display:inline-block;
    border-radius:0.5em;    

    img{
        width:116px;
        height:66px;
        margin:5px;
        border:1px solid #999;
        box-shadow:0px 1px 2px rgba(0,0,0,0.5);
        display:inline-block;        
    }

    &:hover{
         cursor:pointer;
    }

    &:active{
        background-color: $light20Color;
        .splash-thumbnail-name{
            color: $darkColor;
        }       
    }

    &.selected{
        background-color: $light20Color;
        .splash-thumbnail-name{
            color: $darkColor;
        }
        cursor:default;
    }
}

.splash-thumbnail-name{
    font-size:12px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    color:$mainColor;
    margin:5px;
    text-transform:uppercase;
}

.inspector-form > .nanoscroller > .nano-content{
    padding-left:15px;
    padding-right:15px;
}

.inspector-input{
    outline: 0;
    border:none;
    color: $mainColor;
    font-size:16px;
    width:100%;
    background-color:rgba(192,192,192,0.1);
    border-radius: 0.3em;
    padding:4px;
    box-sizing: border-box;

    &:hover{
        background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus{
        color: $highlightColor;
        background-color: $light40Color;
    }

    &.ng-invalid{
        background-color:$inputAlertBackground;
        color:$alertColor;

        &:focus{
           background-color:$lightAlertColor;
        }
  }

}
.inspector{
    h3{
        padding-bottom:5px;
    }

    input[type=number]{
        text-align:right;
    }
}



.inspector-controls{
    padding:2px 0px;

    label{
        display:inline-block;
        margin-bottom:5px;
        color:var(--main-clr);
        line-height:25px;
        clear:both;
    }

    &:first-child{
        padding-top:10px;
    }
}

.inspector-hr{
    margin:5px 0px;
    border-top: 1px solid rgba(0,0,0,0.15);
    border-bottom: none;
    clear:both;

    &:last-child{
        margin-bottom: 20px;
    }
}

.inspector-active-area{
    display:inline-block;
    float:right;

    span{
        color:var(--main-clr);
    }

    &.nofloat{
        float: none;
        margin-left:15px;
    }
}


