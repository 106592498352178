@CHARSET "UTF-8";

n2s-calendar {

  display: inline-block;
  margin: 5px;
  background-color: rgba(255,255,255,0.8);
  border-radius:0.5em;
  width:200px;

  span{
      margin: 0px 0px !important;
  }

  .week input {
    display: block;
    float: left;
    width: 27px !important;
    height: 20px;
    text-decoration: none;
    font-size: 12px;
    margin-left: 1px;
    margin-bottom:1px;
    text-align: center;
    line-height: 20px !important;
    color: #666;
    padding: 0px;
    background-color: transparent;
    border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    border: none !important;
    font-family: inherit;
    outline: none !important;
    cursor:pointer;
  }

  .timerow a {
    display: block;
    float: left;
    width: 23px;
    height: 20px;
    text-decoration: none;
    font-size: 10px;
    margin-left: 1px;
    margin-bottom:1px;
    text-align: center;
    line-height: 20px;
    color: #666;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
  }

  .week input:hover, .timerow a:hover {
    background-color: #DDD;
  }

  .week input.note, .timerow a.note, .week input.note:hover, .timerow a.note:hover {
    background-color: $highlightColor;
    color: #fff;
  }

  .week input {
    &.holiday {
      color: red;
    }

    &.note.holiday {
      background-color: $highlightColor;
      color: #fff;

      &:hover {}

      background-color: $highlightColor;
      color: #fff;
    }

    &.empty {
      opacity: 0.3;
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .n2s-calendar-month {
    .month-name {
      height: 22px;
      padding: 2px 0px;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      width: 155px;
      text-shadow: 0 1px 0 #ddd;
      height: 20px;
      color: $highlightColor;
      line-height: 20px;
      float: left;
    }

    .previous-month, .next-month {
      display: block;
      float: left;
      width: 20px !important;
      height: 20px;
      font-size: 20px;
      padding: 0px 0 0 0;
      margin-left: 1px;
      text-align: center;
      line-height: 20px;
      color: #ddd;
      margin-top: 3px;
      font-family: score;
      background-color: transparent;
      border: none !important;
      outline: none !important;
      cursor:pointer;
    }

    .previousMonth:hover, .nextMonth:hover {
      color: #999;
    }
  }


  .n2s-calendar-weekdays{
    height: 14px;
    clear: both;
    padding: 2px 0px;
    line-height:20px;
    font-weight:bold;    
    border-bottom: 1px solid #ddd;
    font-size: 12px;

    span {
      display: block;
      float: left;
      width: 28px;
      color: $highlightColor;
      text-align: center;
    }
  }

  .monthDays {
    display: block;
    padding: 0px;
  }

  div {
    &.controls {
      width: 102px;
      margin: auto;
    }

    &.timetitle {
      background-color: #ccc;
      color: #fff;
      padding: 2px;
      text-align: center;
      margin: 3px 0px;
    }

    &.digit {
      text-align: center;
      float: left;
    }

    &.digit-separator {
      text-align: center;
      float: left;
      padding-top: 13px;
    }

    &.digit {
      div.spin {
        font-family: score;
        font-size: 30px;
        color: #ddd;
        height: 0px;
        line-height: 15px;
        cursor: pointer;

        &.down {
          padding-top: 3px;
        }
      }

      span {
        margin-top: 20px;
        font-size: 20px;
        width: 20px;
        text-align: center;
      }
    }

    &.digit-separator span {
      font-size: 20px;
      width: 20px;
      text-align: center;
      color: #999;
    }

    &.digit div.spin:hover {
      color: #999;
    }
  }

  &.filled-bkg{
    margin-top:20px;
    background-color: $controlBackground;
    padding:10px;

    .previous-month, .next-month{
      color: $trans30Color;
    }

    .n2s-calendar-weekdays{
      border-color: $trans30Color;
    }
  }  
}

input {
  &[type=datetimepicker], &[type=datepicker], &[type=timepicker] {
    border: 1px solid #ddd;
    display: block;
    padding: 2px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    outline: 0px;
    font-family: "Open Sans";
    -webkit-user-select: none;

    /* Chrome all / Safari all */
    -moz-user-select: none;

    /* Firefox all */
    -ms-user-select: none;

    /* IE 10+ */

    /* No support for these yet, use at own risk */
    -o-user-select: none;
    user-select: none;
  }

  &[type=datetimepicker]:focus, &[type=datepicker]:focus, &[type=timepicker]:focus {
    border: 1px solid #3498db !important;
  }

  &[type=datetimepicker][readonly], &[type=datepicker][readonly], &[type=timepicker][readonly] {
    background-color: white !important;
  }

  &[type=datetimepicker] {
    width: 140px;
    padding-right: 25px;
  }

  &[type=timepicker] {
    width: 86px;
  }

  &[type=datepicker] {
    width: 80px;
    padding-right: 25px;
  }
}

i.clearpicker {
  font-family: score;
  position: relative;
  left: -20px;
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
  color: var(--main-clr);
  font-size: 15px;
  line-height: 20px;

  &:hover {
    opacity: 1;
  }
}

.datetimePicker div.digit input.spin {
  font-family: score;
  font-size: 30px;
  color: #ddd;
  height: auto;
  background-color: transparent;
  line-height: 15px;
  cursor: pointer;
  display: block;
  padding: 0px;
  margin: 0px;
  border: none !important;
  outline: none !important;

  &:hover {
    color: #999;
  }
}
