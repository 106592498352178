.inner-frame {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 3px;
  left: 3px;
}

.avatar-layout1 div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.avatar-layout2 div {
  &:nth-child(1) {
    height: 100%;
    background-size: cover;
    position: absolute;
    background-position: center;
    width: 44px;
  }

  &:nth-child(2) {
    height: 100%;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 46px;
    width: 44px;
  }
}

.avatar-layout3 div {
  &:nth-child(1) {
    height: 100%;
    background-size: cover;
    position: absolute;
    background-position: center;
    width: 44px;
  }

  &:nth-child(2) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 46px;
    width: 44px;
  }

  &:nth-child(3) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 46px;
    top: 46px;
    width: 44px;
  }
}

.avatar-layout4 div {
  &:nth-child(1) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 0px;
    width: 44px;
  }

  &:nth-child(2) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 0px;
    top: 46px;
    width: 44px;
  }

  &:nth-child(3) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 46px;
    top: 0px;
    width: 44px;
  }

  &:nth-child(4) {
    height: 44px;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 46px;
    top: 46px;
    width: 44px;
  }
}

.avatar-in {
  animation-name: avatar-in;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes avatar-in {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  90% {
    transform: scale(0.8);
    opacity: 0.4;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.avatar-out {
  animation-name: avatar-out;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes avatar-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

n2s-multiavatar {
  display: block;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  padding: 3px;
  border: 3px solid $bigAvatarBorderColor;
  position: relative;
  //cursor: pointer; 
  background-color:white;
  margin:auto;


   &.table-size{
    width:34px;
    height:34px;
    border: 2px solid $avatarBorderColor;
    padding:1px;

    .inner-frame{
      top: 1px;
      left: 1px;
      width:34px;
      height:34px;
    }

    .avatar-layout2 div {
      &:nth-child(1) {
        width: 16px;
      }

      &:nth-child(2) {
        left: 18px;
        width: 16px;
      }
    }

    .avatar-layout3 div {
      &:nth-child(1) {
        width: 16px;
      }

      &:nth-child(2) {
        height: 16px;
        left: 18px;
        width: 16px;
      }

      &:nth-child(3) {
        height: 16px;
        left: 18px;
        top: 18px;
        width: 16px;
      }
    }

    .avatar-layout4 div {
      &:nth-child(1) {
        height: 16px;
        left: 0px;
        width: 16px;
      }

      &:nth-child(2) {
        height: 16px;
        left: 0px;
        top: 18px;
        width: 16px;
      }

      &:nth-child(3) {
        height: 16px;
        left: 18px;
        top: 0px;
        width: 16px;
      }

      &:nth-child(4) {
        height: 16px;
        left: 18px;
        top: 18px;
        width: 16px;
      }
    }

  }
}

