n2s-slider {
  display: inline-block;
  width: 100%;
  min-width:100px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div {
  &.n2s-slider-ruler {
    position: absolute;
    left: 10px;
    right: 10px;
    background-color: $dark10ControlBackground;
    height: 5px;
    top: 10px;
  }

  &.n2s-slider-rail {
    width: 100%;
    height: 25px;
    position: relative;
  }
}

.n2s-slider-cursor {
  display: inline-block;
  top:2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  opacity: 1;
  background-color: $controlBackground;
  border: 2px solid $light20Color;
  box-sizing: border-box;
}
