n2s-avatar-editor {
  display:block;

  .n2s-avatar-editor-image{
  	cursor: move;
  }

	.size-tag{
		color: white;
		padding: 0px 5px;
	}

  .n2s-avatar-editor-hsize{
	position:absolute;
	width:100%;
	background-color:white;
	height:3px;

	.size-tag{
		position:relative;
		top: 4px;

		transform-origin: 50%;
		text-align: center;
	}

	.icon-chevron-left{  	
		position: absolute;
		left: -6px;
		top: -6px;
	}

	.icon-chevron-right{  	
		position: absolute;
		right: -6px;
		top: -6px;
	}	

  }


	.n2s-avatar-editor-vsize{
		position:absolute;
		top:0px;
		background-color:white;
		width:3px;

		.size-tag{
			top: 50%;
			position: absolute;
			transform: rotate(270deg);
			left: -36px;
			transform-origin: 50%;
		}

	  .icon-chevron-left{  	
	  	position: absolute;
	    left: -5px;
	    top: -6px;
		transform: rotate(90deg);
		transform-origin: 50%;	    
	  }

	  .icon-chevron-right{  	
	  	position: absolute;
	    right: -5px;
	    bottom: -7px;
		transform: rotate(90deg);
		transform-origin: 50%;	    
	  }

	}


}


