@CHARSET "UTF-8";

n2s-select {
  display: inline-block;
  position: relative;
  outline: none;
  max-width:100%;
  font-size:14px;
  text-align:left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;

  div.select-control {
    &:focus {
      outline: none;
    }

    border-radius: 0.3em;
    padding: 4px;
    background-color: $controlBackground;
    box-sizing: border-box;
    color: $highlightColor;

    div.select-selection {
      text-align:left;
      display:inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;      
      margin-right: 1.5em;
    }

  }

  i.icon-arrow-down5 {
    position: absolute;
    top: 0px;
    right: 0.2em;
    color: $highlightColor;
    font-size: 1.4em;
    line-height: 1.1em;
    padding-top: 0.1em;
  }

  &:hover i.icon {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  div.updating {
    display: none;
  }

  &.updating {
    i.icon-arrow-down5 {
      display: none;
    }

    div.updating {
      display: block;
      opacity: 1;
      width: 1em;
      height: 1em;
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      vertical-align: middle;
      text-align: center;
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-animation: spin-counterclockwise 1s linear infinite;
      -moz-animation: spin-counterclockwise 1s linear infinite;
      -o-animation: spin-counterclockwise 1s linear infinite;
      animation: spin-counterclockwise 1s linear infinite;

      i {
        font-size: 1em;
        margin: 0px;
        position: absolute;
        top: 0.115em;
        left: 0px;
        opacity: 0.7;
        line-height: 0.825em;
      }
    }
  }

  &.error {
    .select-control {
      background-color: rgba(255, 0, 0, 0.5);
      color: red !important;
    }

    .icon {
      color: red !important;
    }
  }

  .n2s-select-size-holder{
    height:0px;
    overflow:hidden;
    max-width:100%;
    margin-right:1.5em;
  }

  &.small{
    font-size:12px;
    
    .select-control {
      padding: 0.3em 0.2em;
    }

    i.icon {
      font-size: 1.2em;

      &.icon-arrow-down5{
        padding-top:0.2em;
      }
    }
  }
}

.select-options {
  background-color: $controlBackground;
  border-radius: 0.3em;
  padding: 6px 1px;
  /*border: 0.1em solid rgb(132, 193, 228);*/
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 20000;
}

n2s-select {

  &.selecting .select-options {
    display: block;
    opacity: 0;
  }

  &.open .select-options {    
    opacity: 1;
  }


  &.updating {
    .select-options {
      display: none;
    }

    cursor: default;
  }


  &.ng-invalid{
    div.select-control{
      background-color:$inputAlertBackground;
      color:$alertColor;

      &:focus{
         background-color:$lightAlertColor;
      }
    }

    i.icon-arrow-down5 {
      color: $alertColor;
    }    
  }

}

ul.option-list {
  margin: 0px;
  padding-left: 0px;
  display:inline-block;
  min-width:100%;

  li {
    list-style-type: none;
    padding: 2px 15px 2px 5px;
    margin: 0px;
    //display: inline-block;
    //width: 100%;
    box-sizing: border-box;
    text-align:left;
    cursor: pointer;
    color: $highlightColor;
    white-space: nowrap;

    div{

      &.name{
        float: left;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        color: $highlightColor;
        display: block;
        box-sizing: border-box;
        padding: 2px 5px;
      }

      &.detail {
        float:left;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        color: $highlightColor;
        display: block;
        box-sizing: border-box;
        padding: 0px 5px;
        opacity:0.5;
        font-size:0.8;
      }
    }

    &.selected {
      background-color: $light10Color !important;
      color: white;

      div {
        &.name {
          color: white;
        }
        
        &.detail {
          color: white;
        }        
      }

      count {
        color: $highlightColor;
        background-color: $controlBackground;
      }
    }
  }
}


n2s-select.overlay-control{
  
  font-size:25px;

  i.icon-arrow-down5{
    color:white;
  }

  .select-control{
    color:white;
    background-color:transparent;
  }

  .select-options{
    background-color:#808080;
    color:white;
    box-shadow:none;
  }

  .option-list{
    li{
    
      div{
        &.name{
          color:white;
        }

        &.detail {
          color: white;
        }    
      }

      &.selected {
        background-color: white !important;

        div {
          &.name {
            color: #808080;
          }
          
          &.detail {
            color: #808080;
          }        
        }
      }
    }
  }

}


#n2sSelectList{
  width:100%;
}
