@CHARSET "UTF-8";

n2s-table-filter {
  display: block;
  width: 100%;
  margin: 0px;
  clear: both;
}

.n2s-page-filters-wrapper{
  position:relative;
  top:-1px;
}

.n2s-table-filter-row {
  width: 100%;
  //border-top: 1px solid #CEE4EF;
  border-top: 1px solid $light30Color;
  //background-color: rgba(224, 235, 246, 1);
  background-color: $light40Color;
  color: $light20Color;
  font-size: 12px;
  margin: 0px;
  display: table;
  clear: both;
  padding-top:2px;
  height: 39px;
  border-collapse: collapse;

  n2s-select{
    font-size:12px;
    line-height:1em;
  }


  div {
    &.filter, &.controls {
      display: table-cell;
      line-height: 25px;
      padding: 6px 0px 0px 15px;
    }


    &.filter {
      input.n2s-table-filter-select {
        padding: 0px 10px;
        margin-top: 6px;
        font-size: 12px;
        margin-bottom: 9px;
      }

      select.n2s-table-filter-select {
        text-transform: uppercase;
        margin-right: 10px;
      }

      input.n2s-table-filter-input {
        margin-right: 10px;
        margin-bottom: 10px;
        line-height: 20px;
      }
    }

    &.controls {
      position:relative;
      width: 50px;

      & > div{
        position: absolute;
        right: 10px;
        top: 11px;
      }

      i.icon {
        font-size: 20px;
        color: #319DD4;
        opacity: 0.5;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.n2s-table-filter-arrow {
  position: absolute;
  top: -12px;
  right: 45px;
  margin: 0;
  border-top: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgba(206, 228, 239, 1);
  padding: 0;
  width: 0;
  height: 0;

  &:before {
    top: -13px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $light40Color;
    border-width: 14px;
    left: 50%;
    margin-left: -14px;
  }
}

.n2s-table-filter-field{
  float:left;
  width:200px;
  text-transform:uppercase; 
  //color:$highlightColor;
  color:$highlightColor;
  margin-right: 5px;
  line-height:0px;

  n2s-spinner{
    //background-color: rgb(246, 249, 252);
    background-color: $light55Color;;
    width:100%;
  }

  & > n2s-select{
    top:0px;

    div.select-options{
      background-color: $light45Color !important;
    }

    div.select-control{
      background-color: $light45Color !important;
      
      &:hover{
        background-color: $light55Color !important;  
      }

      .select-selection{
        line-height:1.5em;
        height:1.5em;
      }

      .icon-arrow-down5{
        line-height:1.3em;
        font-size:1.5em;

      }
    } 
  } 
}


.n2s-table-filter-separator{
  width: 2px;
  background-color:$light30Color;
  margin:4px 10px;
  height:30px;
}

.n2s-table-filter-context {
  float: left;
  position: relative;
  & > * {
    float:left;
  }
  .filter {
    width: auto;
    height: 75px;

    n2s-multiavatar, .multiavatar {
      width: 40px;
      height: 40px;
      float: left;
      margin: auto 15px auto auto;

      .inner-frame {
        width: 40px;
        height: 40px;
      }
      .table-cell-avatar {
        width: 50px;
        height: 50px;
        i.icon {
          font-size: 28px;
          line-height: 32px;
          padding: 6px;
          top: 2px;
          left: 2px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .context-description {
      margin-top: 10px;
      color: $highlightColor;
    }

    .context-detail {
      color: $highlightColor;
    }

    .context-type-badge {
      width: 10px;
      height: 6px;
      top: 37px;
      left: 50px;
      [class^="context-icon-"], [class*=" context-icon-"] {
        font-size: 11px;
      }
    }
  }
}

.with-fixed-filters-big {
  .n2s-table-filter-input {
    margin-left: 25px;
  }
  .n2s-table-filter-field {
    line-height: 56px;
  }
}

.n2s-table-filter-input{
  
  float:left;

  & > * {
    float:left;
  }

  div.n2s-table-filter-operation{
    margin: 0px 5px;
    display:inline-block;
    text-transform:uppercase; 
    width:75px;
    text-align:center;
    color:$highlightColor;
    text-shadow:0px 1px rgba(255,255,255,1);

    &:first-child{
      width: 75px;
      text-align:left;
    }
  }

  div.n2s-table-filter-unit{
    margin: 0px 5px;
    display:inline-block;
    width:75px;
    text-align:center;
    color:$highlightColor;
  }

  n2s-select{
    top:0px;

    div.select-options{
      background-color: $light45Color !important;
    }

    div.select-control{
      background-color: $light45Color !important;
      
      &:hover{
        background-color: $light55Color !important;  
      }

      .select-selection{
        line-height:1.5em;
        height:1.5em;
      }

      .icon-arrow-down5{
        line-height:1.4em;
        font-size:1.5em;

      }
    }
  }


  n2s-datepicker {
    .n2s-datepicker-input{
      //padding: 4px 30px 11px 30px !important;
      //line-height:21px !important;
      color:$highlightColor;
      background-color: $light45Color !important;
      border: 2px solid $light30Color !important;

      &:hover, &:focus{
        background-color: $light50Color !important;
        border: 2px solid $light50Color !important;
      }
    }
  }

  n2s-filter-input, n2s-searchbox, n2s-searchinput {

    input{
      //padding: 10px 30px 11px 30px !important;
      padding: 2px 30px 2px 30px !important;
      line-height:21px !important;
      color:$highlightColor;
      background-color: $light45Color !important;
      border: 2px solid $light30Color !important;

      &:hover, &:focus{
        background-color: $light50Color !important;
        border: 2px solid $light50Color !important;
      }

    }

    .n2s-searchbox-container{
      margin:0px;
      top: -2px;
    }

    div.clear{
      line-height:20px;
      font-size: 18px;
      right:2px;
      top:5px;
    }

    div.icon{
      font-size: 18px;
      left:8px;
      top:3px;
      height: 18px;
      //line-height: 20px;
      line-height: 25px;
    }

  }

}


.select-popup.n2s-table-filter-select {  
  text-transform: uppercase;
  margin-right: 10px;

  #n2sSelectListPopup{
    background-color: $light45Color !important;
    font-size: 12px;
  }

}


.n2s-fixed-filter{

  min-width: $minBodyWidth;

  .n2s-table-filter-row{
    border-bottom: 1px solid $light30Color;
  }

  .n2s-table-filter-input{
    line-height:38px;
  }

  .n2s-table-color-marker{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 8px;
    position: relative;
    margin-right: 10px;
    margin-left: 20px;
  }


  .n2s-table-filter-field{
      position:relative;
      top:4px;
      width:auto;

      n2s-select{
        top:2px;
        position:relative;
      }


      input{
        color:$highlightColor;
        background-color: $light45Color !important;
        border: 2px solid $light30Color !important;

        &:hover, &:focus{
          background-color: $light50Color !important;
          border: 2px solid $light50Color !important;
        }
      }
  }
}


